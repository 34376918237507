import { Box, Typography, useTheme } from "@mui/material";
import Blogs from "./blogs";
import Subscribe from "../../../components/Subscribe";
import Banner from "src/components/Banner";
import GoogleReviews from "src/components/googleReviews";
import { Helmet } from "react-helmet-async";

const Home = () => {
  const theme = useTheme();
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Helmet>
          <title>Blogs | T-Bros Auto Detailing</title>
          <link
            id="canonical-tag-head"
            rel="canonical"
            href={window.location.href}
          />
        </Helmet>
        <Banner
          url="/assets/img/blog-banner.png"
          colorSx={{
            backgroundImage: "unset",
            mt: 0,
            color: "primary",
            py: 10,
          }}
        >
          <Typography
            variant="h1"
            component="h1"
            sx={{ color: theme?.colors?.primary.main, py: 2 }}
          >
            OUR BLOG
          </Typography>
          {/* <Typography
            variant="h6"
            component="h6"
            sx={{ color: theme?.colors?.alpha.white[100], fontSize: 20 }}
          >
            Lorem ipsum dolor sit amet, consectetur
            <br /> adipiscing elit, sed do eiusmod tempor 
            <br /> detailing onlineincididunt
          </Typography> */}
        </Banner>
        
        <Blogs />
        <GoogleReviews />
        <Subscribe />
      </Box>
    </>
  );
};

export default Home;
