import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Stack, Typography } from "@mui/material";
import Subscribe from "src/components/Subscribe";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import FAQItem from "src/components/FAQS";
import { undercoatingRust } from "src/components/FAQS/mockData";
class Index extends Component {
  state = {
    display: true,
    width: 300,
  };
  render() {
    const PrevArrow = (props) => {
      const { onClick } = props;
      return (
        <button
          className="carousel-control-prev"
          onClick={onClick}
          style={{
            left: "10px",
            zIndex: 1,
            backgroundColor: "#234DEF",
            width: "4%",
            borderRadius: "61%",
            height: "13%",
            margin: "auto",
          }}
        >
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="visually-hidden">Previous</span>
        </button>
      );
    };

    const NextArrow = (props) => {
      const { onClick } = props;
      return (
        <button
          className="carousel-control-next"
          onClick={onClick}
          style={{
            right: "10px",
            zIndex: 1,
            backgroundColor: "#234DEF",
            width: "4%",
            borderRadius: "61%",
            height: "13%",
            margin: "auto",
          }}
        >
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="visually-hidden">Next</span>
        </button>
      );
    };
    const slickSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
      responsive: [
        {
          breakpoint: 900, // adjust the breakpoint as needed
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: false,
            nextArrow: false,
          },
        },
      ],
    };
    return (
      <div>
        <Helmet>
          <title>Vehicle Undercoating & Rust Protection in Calgary AB</title>
          <meta
            name="description"
            content="T-Bros Auto Detailing provides professional car undercoating & rust protection services in Calgary AB. Affordable and the best solutions for your vehicle!"
          />
          <meta
            name="keywords"
            content="car undercoating & rust protection services in calgary ab, expert vehicle undercoating & rust protection services in calgary ab, best auto undercoating & rust protection services in calgary ab, Professional car undercoating & rust protection services in calgary ab, affordable auto undercoating & rust protection services in calgary ab"
          />
          <link
            id="canonical-tag-head"
            rel="canonical"
            href={window.location.href}
          />
        </Helmet>
        <section className="about-page about1 whychhose">
          {/* Top banner section */}
          <div className="carpolish">
            <div className="container">
              <div className="row">
                <div className="title-inner">
                  <h1 style={{ color: "var(--yellow)" }}>
                    Undercoating/Rust
                    <br /> Protection
                  </h1>
                  <p>
                    Shield your vehicle from corrosion with our{" "}
                    <strong>
                      undercoating and rust <br />
                      protection service in Calgary,
                    </strong>{" "}
                    ensuring long-lasting durability <br />
                    against harsh elements and road conditions.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <section className="spce-contact1 mt-6">
            <div className="container">
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <h2 style={{ fontWeight: 600 }}>
                    The Power Behind Vehicle Protection:{" "}
                    <span style={{ color: "#234DEF" }}>
                      Expert Vehicle Undercoating and Rust Protection
                    </span>{" "}
                    <br />
                    in Calgary{" "}
                  </h2>
                  <p className="mt-3">
                    Protecting your vehicle is the priority of most of us as we
                    invest a lot in bringing our dream rides home! That is why
                    we at T-Bros auto detailing services in Calgary ensure
                    providing solutions that go way beyond just something
                    ordinary give-and-take process.
                  </p>
                  <p className="mt-3">
                    Auto undercoating and rust protection in Calgary refers to
                    the process of applying a protective layer to the underside
                    of the vehicle to protect it from road salt, corrosion,
                    rust, and road debris. The unique thing about undercoating
                    and rust protection services is that they prevent moisture
                    and other harmful substances from penetrating the vehicle's
                    metal surface and causing damage.
                  </p>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 mb-3">
                  <img
                    className="imgwidth aos-init aos-animate"
                    src="/assets/img/rust-img1.webp"
                    data-aos="fade-right"
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="bg-blue pdingy mt-6">
            <div className="container">
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-6 col-md-12 col-sm-12 mb-3">
                  <img
                    className="imgwidth aos-init aos-animate"
                    src="/assets/img/rust-img2.webp"
                    data-aos="fade-right"
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 color56">
                  <h2 style={{ fontWeight: 600, color: "#fff" }}>
                    <span style={{ color: "#FCF003" }}>Why Undercoat</span> Your
                    Vehicle?{" "}
                  </h2>
                  <div className="row check-cion45 mt-4">
                    <div className="col-1">
                      <img src="/assets/img/check-icon.svg" />
                    </div>
                    <div className="col-10">
                      <h4>Rust Prevention</h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1">
                      <img src="/assets/img/check-icon.svg" />
                    </div>
                    <div className="col-10">
                      <h4>Increased Resale Value</h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1">
                      <img src="/assets/img/check-icon.svg" />
                    </div>
                    <div className="col-10">
                      <h4>Help Reduce Road Noise</h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1">
                      <img src="/assets/img/check-icon.svg" />
                    </div>
                    <div className="col-10">
                      <h4>Prolong the Life of Your Vehicle</h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1">
                      <img src="/assets/img/check-icon.svg" />
                    </div>
                    <div className="col-10">
                      <h4>Reduce Repair Costs</h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="link-btn widhtbtn mt-3">
                      <Link to="/booking">CHECK OUR PACKAGES</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-center mt-5">
                <h2
                  style={{
                    fontWeight: 600,
                    textAlign: "center",
                    color: "#fff",
                  }}
                >
                  Mineral Oil-Based Coating:{" "}
                  <span style={{ color: "#fcf003" }}> Professional Rust </span>
                  Undercoating Services in Calgary
                </h2>
              </div>
            </div>
          </section>
          <section className="car-type1">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12 ">
                  <div className="img-car">
                    <img src="/assets/img/caricon.webp" />
                    <h3>Standard car</h3>
                    <p>
                      The mineral oil-based coating procedure involves applying
                      a specialized solution to the car's surfaces. This coating
                      nourishes and rejuvenates, offering protection and a fresh
                      appearance, enhancing longevity and shine.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 ">
                  <div className="img-car">
                    <img src="/assets/img/suv-icon.webp" />
                    <h3>Car/SUV</h3>
                    <p>
                      The mineral oil-based coating procedure for an SUV
                      involves the application of a specialized formulation to
                      enhance and protect surfaces, providing a deep,
                      long-lasting shine while protecting against environmental
                      factors.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 ">
                  <div className="img-car">
                    <img src="/assets/img/truc-icon.webp" />
                    <h3>Truck</h3>
                    <p>
                      Revitalize your truck's exterior with a mineral oil-based
                      coating. Our procedure involves an expert application,
                      providing a protective layer for lasting shine and hazard
                      protection.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mt-4 justify-content-center mb-5">
                <div className="link-btn widhtbtn m-auto mt-5 mb-10 ">
                  <Link to="/booking">CHECK OUR PACKAGES</Link>
                </div>
              </div>
            </div>
          </section>
          <section className="blue-bg7 mb-5">
            <div className="container">
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-5 col-md-12 col-sm-12">
                  <div className="img-car4">
                    <img
                      className="img-fluid rounded"
                      alt="ceramic coating services in Calgary"
                      src="/assets/img/carpolish4.png"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 color-white py-5">
                  <h2
                    style={{
                      fontWeight: "bold",
                      textAlign: "left",
                      color: "var(--white)",
                      paddingBottom: "10px",
                    }}
                  >
                    Why Opt for{" "}
                    <span style={{ color: "var(--yellow)" }}>
                      Undercoating and Rust Protection Services in Calgary?
                    </span>
                  </h2>
                  <p>
                    Undercoating and{" "}
                    <strong>rust protection services in Calgary </strong> offer
                    several benefits to your vehicle:
                  </p>
                  <div className="video-text035">
                    <ol>
                      <li className="bullet1 mb-3">
                        The protective layer forms a barrier that shields the
                        underside of your vehicle from rust and corrosion caused
                        by exposure to moisture, road salt, debris, and other
                        environmental pollutants.
                      </li>
                      <li className="bullet1 mb-3">
                        {" "}
                        Undercoating services in Calgary help increase the
                        resale value of your car by preventing it from being
                        damaged by different hazards on the road and the
                        environment.
                      </li>
                      <li className="bullet1 mb-3">
                        <strong>Undercoating services in Calgary</strong>{" "}
                        provide extra protection to vulnerable metal components
                        such as the chassis, frame, and suspension parts. This
                        helps to reduce wear and tear and increases the life of
                        these components, eliminating the need for costly
                        repairs.
                      </li>{" "}
                      <li className="bullet1 mb-3">
                        Auto undercoating services in Calgary help to reduce
                        road noises and vibrations, creating a comfortable
                        driving experience.
                      </li>
                      <li className="bullet1 mb-3">
                        In addition to its protective benefits,{" "}
                        <strong>undercoating services in Calgary</strong> also
                        help to improve the appearance of your vehicle by
                        covering up light rust and corrosion spots, giving it a
                        cleaner and more polished look.
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="bg-blue pdingy mt-6">
            <div className="container">
              <div className="row align-items-center color-white">
                <h2
                  style={{
                    fontWeight: 600,
                    textAlign: "center",
                    color: "#fff",
                  }}
                >
                  Get <span style={{ color: "#fcf003" }}>Protected</span> as
                  Early <br />
                  as <span style={{ color: "#fcf003" }}>Possible!</span>{" "}
                </h2>
                <p style={{ color: "#fff", textAlign: "center" }}>
                  Professional Undercoating Services In Calgary for Your Car
                </p>
              </div>
            </div>
          </section>
          <section className="car-type1 img-width">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="card-box yellowbg aos-init"
                    data-aos="flip-right"
                  >
                    <div className="svg-img">
                      <img
                        src="/assets/img/undercoating1.webp"
                        data-aos="fade-right"
                        className="aos-init aos-animate"
                      />
                    </div>
                    <h4>Stop Rust</h4>
                    <p>
                      Banish rust and restore your vehicle's glory with our
                      expert{" "}
                      <strong>
                        undercoating and rust protection services in Calgary.
                      </strong>
                      Unveil a sleek, corrosion-free look that lasts.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="card-box bluebg1 aos-init aos-animate"
                    data-aos="flip-right"
                  >
                    <div className="svg-img">
                      <img
                        src="/assets/img/undercoating2.webp"
                        data-aos="fade-right"
                        className="aos-init aos-animate"
                      />
                    </div>
                    <h4>Moisture Barrier</h4>
                    <p>
                      Moisture barrier in{" "}
                      <strong>undercoating and rust protection</strong>
                      shields the vehicle, preventing corrosion by creating a
                      protective layer that resists water and humidity.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="card-box yellowbg aos-init"
                    data-aos="flip-right"
                  >
                    <div className="svg-img">
                      <img
                        src="/assets/img/undercoating3.webp"
                        data-aos="fade-right"
                        className="aos-init aos-animate"
                      />
                    </div>
                    <h4>Road Debris Protection</h4>
                    <p>
                      Our professional undercoating services in Calgary involve
                      applying a coating to the vehicle's undercarriage,
                      shielding it from rocks, gravel, and other damaging
                      elements present on roads.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="card-box bluebg1 aos-init aos-animate"
                    data-aos="flip-right"
                  >
                    <div className="svg-img">
                      <img
                        src="/assets/img/undercoating4.webp"
                        data-aos="fade-right"
                        className="aos-init aos-animate"
                      />
                    </div>
                    <h4>Heat Protection</h4>
                    <p>
                      Heat protection involves applying specialized coatings to
                      shield a vehicle's components from excessive heat,
                      enhancing performance and preventing damage from high
                      temperatures.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="card-box yellowbg aos-init"
                    data-aos="flip-right"
                  >
                    <div className="svg-img">
                      <img
                        src="/assets/img/undercoating5.webp"
                        data-aos="fade-right"
                        className="aos-init aos-animate"
                      />
                    </div>
                    <h4>Salt and Chemical Resistance</h4>
                    <p>
                      Salt and chemical resistance in undercoating services in
                      Calgary involves creating a protective barrier to shield
                      the vehicle from corrosive effects caused by road salt and
                      other chemicals.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="card-box bluebg1 aos-init aos-animate"
                    data-aos="flip-right"
                  >
                    <div className="svg-img">
                      <img
                        src="/assets/img/undercoating6.webp"
                        data-aos="fade-right"
                        className="aos-init aos-animate"
                      />
                    </div>
                    <h4>Soundproofing</h4>
                    <p>
                      Soundproofing in undercoating services in Calgary dampens
                      vibrations and reduces road noise, enhancing driving
                      comfort and creating a quieter vehicle interior
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="container mt-5 mb-10 p-0">
            <div className="row larg-text mb-2 mt-3 text-center">
              <h2>
                OUR <span style={{ color: "var(--blue)" }}> WORK</span>
              </h2>
            </div>
            <Stack>
              <div className="work-img">
                <Slider {...slickSettings}>
                  <div className="img-work1">
                    <img src="/assets/img/ourwork01.webp" alt="Work 1" />
                  </div>
                  <div className="img-work1">
                    <img src="/assets/img/ourwork02.webp" alt="Work 2" />
                  </div>
                  <div className="img-work1">
                    <img src="/assets/img/ourwork03.webp" alt="Work 3" />
                  </div>
                  <div className="img-work1">
                    <img src="/assets/img/ourwork04.webp" alt="Work 4" />
                  </div>
                  <div className="img-work1">
                    <img src="/assets/img/ourwork05.webp" alt="Work 5" />
                  </div>
                </Slider>
              </div>
            </Stack>
          </div>
          <div className="container">
            <Box sx={{ my: 10 }}>
              <Typography
                variant="h2"
                sx={{ display: "flex", justifyContent: "center", fontSize: 50 }}
                gutterBottom
              >
                FAQ’s
              </Typography>
              {undercoatingRust.map((undercoatingRust) => (
                <FAQItem
                  key={undercoatingRust.id}
                  question={undercoatingRust.question}
                  answer={undercoatingRust.answer}
                />
              ))}
            </Box>
          </div>
          <Subscribe />
        </section>
      </div>
    );
  }
}

export default Index;
