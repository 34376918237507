import React, { useState } from "react";
import { Box, Container, TextField, useTheme, Typography } from "@mui/material";
import { PrimaryButton } from "src/components/Buttons";
import SectionHeader, { HeaderSpan, SectionHeaderTop } from "../SectionHeader";
import SectionContainer from "../SectionContainer";

const Subscribe = () => {
  const theme = useTheme();
  const [emailInput, setEmailInput] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);

  const handleSubmit = (event) => {
    event.preventDefault();
    const enteredEmail = emailInput.trim(); // Trim extra whitespace
    if (validateEmail(enteredEmail)) {
      setIsValidEmail(true);
      // Add your submission logic here
    } else {
      console.error("Invalid email address");
      setIsValidEmail(false);
    }
  };

  const handleEmailChange = (event) => {
    setEmailInput(event.target.value);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <SectionContainer
      sx={{ mb: 0, backgroundColor: theme?.colors?.secondary.main, py: 10}}
    >
      <Container maxWidth="lg" sx={{ position: "relative", }}>
        <SectionHeaderTop
          sx={{ textAlign: "center", color: theme?.colors?.alpha.white[100], fontSize:20, }}
        >
          Get Updated
        </SectionHeaderTop>
        <SectionHeader
          sx={{
            textAlign: "center",
            color: theme?.colors?.alpha.white[100],
            mb: 0.5,
            fontSize:{md:50, sm:50},
            paddingBottom:2,
          }}
        >
          Subscribe to Our <HeaderSpan>News letter</HeaderSpan>
        </SectionHeader>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            width: { md: "65%", xs: "100%" },
            mx: "auto",
            ".MuiOutlinedInput-root": {
              borderColor: "white",
              borderRadius: 0,
              backgroundColor: "#03229AB0",
            },
            display: "flex",
            flexDirection: "center", // Align children in a column
            alignItems: "flex-start",
          }}
        >
          <TextField
            name="email"
            type="text"
            sx={{
              width: { md: "80%", xs: "100%" },
              color: "white",
          
            }}
            label=""
            placeholder="Email ID..."
            inputProps={{ style: { height: 11, color: "white" } }}
            value={emailInput}
            onChange={handleEmailChange}
            error={!isValidEmail}
            helperText={
              !isValidEmail && (
                <Typography
                  sx={{
                    color: "red",
                    fontSize: 15,
                    textTransform: "none",
                    marginRight:2
                  }}
                >
                  Please enter a valid email address.
                </Typography>
              )
            }
          />
          <PrimaryButton
            type="submit"
            sx={{
              width: {
                md: "20%",
                borderRadius: 0,
                color: theme?.colors.alpha.black[100],
              },
            }}
          >
            Submit
          </PrimaryButton>
        </Box>
      </Container>
    </SectionContainer>
  );
};

export default Subscribe;
