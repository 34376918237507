import { Box, Container, Typography, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "src/components/Breadcrumb";
import SectionHeader, { HeaderSpan } from "src/components/SectionHeader";
import Subscribe from "src/components/Subscribe";
import GoogleReviews from "src/components/googleReviews";

const Component = () => {
  const location = useLocation();
  const theme = useTheme();
  const pathname = location?.pathname;
  const blogUrl = pathname?.substring(
    pathname?.lastIndexOf("/") + 1,
    pathname?.length
  );

  const [data, setData] = useState([]);
  useEffect(() => {
    fetch("/blogs.json").then((res) => {
      if (res.status === 200) {
        res.json().then((responseData) => {
          setData(responseData);
        });
      }
    });
  }, []);

  const blog = data?.find((ele) => ele?.url === blogUrl);

  useEffect(() => {
    if (blog?.title) {
      document.title = blog?.title;
    }
  }, [blog?.title]);

  const HeadCallback = useCallback(() => {
    return (
      <Helmet>
        <title>{blog?.title}</title>
        <meta name="theme-color" content={theme?.colors?.secondary?.main} />
        <meta name="title" content={blog?.metaTitle} />
        <meta name="description" content={blog?.metaDescription} />
        <meta name="keywords" content={blog?.metaKeywords} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
    );
  }, [
    pathname,
    blog?.title,
    blog?.metaDescription,
    blog?.metaKeywords,
    theme?.colors?.secondary?.main,
  ]);

  return (
    <>
      <HeadCallback />
      <Box>
        <Breadcrumbs
          title={blog?.breadcrum}
          banner={{
            md: "/assets/img/blog-banner2.png",
            xs: "/assets/img/blog-banner2.png",
          }}
        />
        <Box sx={{ backgroundColor: theme?.colors?.primary.main, mb: 6 }}>
          <Container maxWidth="lg">
            <SectionHeader
              sx={{
                color: theme?.colors?.primary?.main,
                fontWeight: "bold",
                textAlign: "start",
              }}
              component="h1"
            >
              <Box
                sx={{
                  color: theme?.colors?.alpha.black[100],
                  fontSize: { md: 30, xs: 20 },
                  py:5
                }}
              >
                {blog?.title}
              </Box>
            </SectionHeader>
          </Container>
        </Box>

        <Container sx={{  }} maxWidth="lg">
          <Typography
            sx={{ width: "100%", mb: 3 }}
            component="img"
            src={blog?.img}
            alt={blog?.imgAlt}
          />
          <Box
            component="div"
            sx={{ textAlign: "justify" }}
            dangerouslySetInnerHTML={{ __html: blog?.content }}
          />
        </Container>
      </Box>
      <GoogleReviews />
      <Subscribe />
    </>
  );
};

export default Component;
