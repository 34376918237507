import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

import BlogCard from "./Card";
import { useEffect, useState } from "react";
const Blogs = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch("/blogs.json").then((res) => {
      if (res.status === 200) {
        res.json().then((responseData) => {
          setData(responseData);
        });
      }
    });
  }, []);
  const theme = useTheme();
  return (
    <Box sx={{}}>
      <Container
        maxWidth="lg"
        sx={{
          width: { md: "100%", sm: "100%", xm: "50%", xs: "100%" },
          position: "relative",
          mt: 5,
          mb: 4,
        }}
      >
        <Grid
          container
          spacing={5}
          columns={{ xs: 10, sm: 10, md: 10, lg: 15 }}
         
        >
          {data?.map((item: any, index) => (
            <Grid item key={index} xs={10} sm={10} md={5} lg={5} xl={5} >
              <BlogCard
                item={{
                  ...item,
                }}
                index={index}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Blogs;
