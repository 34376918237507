
import * as Yup from "yup";

export const initialValues = {
  name: "",
  email: "",
  phone: "",
  message: "",
  recaptchaValue: "",
};

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Full name  must be at least 3 character")
    .required("Full name is required"),
  email: Yup.string()
    .email()
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email address")
    .required("Email is required"),
  phone: Yup.string()
    .transform((value) => (value ? value.replace(/[^\d]/g, "") : value))
    .matches(/^[0-9]+$/, "Phone number must contain only digits")
    .min(10, "Phone number must be at least 10 digits")
    .max(14, "Phone number must be at most 14 digits")
    .required("Phone number is required"),
  message: Yup.string().required("Message is required"),
  // recaptchaValue: Yup.string().required("reCAPTCHA verification is required"),
});