import Box from "@mui/material/Box";
import DefaultModal, {
  ModalProps as DefaultModalProps,
} from "@mui/material/Modal";
import { DialogTitle, Fade, IconButton, useTheme } from "@mui/material";
import Close from "@mui/icons-material/Close";

export interface ModalProps extends DefaultModalProps {
  width?:
    | string
    | number
    | {
        xl?: string | number;
        lg?: string | number;
        md?: string | number;
        sm?: string | number;
        xs?: string | number;
      };
  backgroundColor?: string;
}
const Modal = (props: ModalProps) => {
  const {
    width = { md: "50%", xs: "100%" },
    backgroundColor,
    title,
    ...restProps
  } = props;
  const theme = useTheme();
  return (
    <DefaultModal
      {...props}
      sx={{ display: "flex", px: 2, py: 1.7, ...props?.sx }}
    >
      <Box sx={{ width: width, m: "auto" }}>
        <Fade style={{ width: "100%" }} in={props?.open}>
          <Box
            sx={{
              backgroundColor:
                backgroundColor || theme?.colors?.alpha?.white[100],
              m: "auto",
              position: "relative",
            }}
          >
            <IconButton
              sx={{
                position: "absolute",
                right: -10,
                top: -10,
                backgroundColor: theme?.colors?.error?.main,
                borderRadius: 5,
                border: `3px solid ${theme?.colors?.alpha?.white[100]}`,
                padding: 0.2,
                zIndex: 9,
                ":hover": {
                  backgroundColor: theme?.colors?.error?.dark,
                },
              }}
              onClick={() => {
                props?.onClose && props?.onClose({}, "escapeKeyDown");
              }}
            >
              <Close
                fontSize="small"
                sx={{ color: theme?.colors?.alpha?.white[100] }}
              />
            </IconButton>
            {props?.title ? (
              <DialogTitle sx={{ fontWeight: "bold", fontSize: 16 }}>
                {props?.title}
              </DialogTitle>
            ) : null}
            <Box
              sx={{
                maxHeight: document?.body?.clientHeight - 25,
                overflow: "auto",
                overflowX: "hidden",
              }}
            >
              {props?.children}
            </Box>
          </Box>
        </Fade>
      </Box>
    </DefaultModal>
  );
};

export default Modal;
