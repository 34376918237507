import { Box, Link, Typography, useTheme } from "@mui/material";
import { Helmet } from "react-helmet-async";

const BlogCard = ({ item, index }) => {
  const theme = useTheme();

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    const truncatedText = text.split(" ").slice(0, maxLength).join(" ");
    return truncatedText + "...";
  };

  return (
    <>
      <Box component={Link} href={"/blog/" + item?.url} key={index} sx={{display:"flex", justifyContent:"space-between"}}>
        <Box
          sx={{
            position: "relative",
            zIndex: "9999 !important",
            mb: 4,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              zindex: 9999,
              right: "-10px",
              top: "-14px",
              background: "#F5E14D",
              width: "48%",
              height: "15%",
              clipPath:
                "polygon(0 0, 82% 0, 100% 81%, 122% 38%, 69% 149%, 0 23%, 1% 72%, 0% 0%)",
            }}
          />
          <Box
            sx={{
              backgroundColor: "#eeeeee",
              zIndex: 0,
              color: "black",
              clipPath:
                "polygon(0 0, 91% 0, 100% 11%, 100% 80%, 100% 100%, 0 100%, 0% 80%, 0% 20%)",
              p: 4,
              transition: "background-color 0.3s",
              "&:hover": {
                backgroundColor: theme?.colors?.secondary.main,
                color: "white",
              },
            }}
          >
            <Box>
              <img
                style={{ width: "100%" }}
                alt="Crop me"
                src={item?.img}
              ></img>

              <Typography
                component="div"
                variant="h4"
                sx={{
                  position: "relative",
                  color: "inherit",
                  pt: { md: 2, xs: 2, sm: 2 },
                  fontSize: { md: 20, sm: 17, xs: 15 },
                  textOverflow: "ellipsis",
                }}
              >
                {truncateText(item?.title, 10)}
              </Typography>
              <Typography
                component="div"
                variant="body1"
                sx={{
                  width: "100%",
                  position: "relative",
                  pt: { md: 2, xs: 2, sm: 2 },
                  fontSize: { md: 16 },
                  color: "inherit",
                }}
              >
                {truncateText(item?.title, 10)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BlogCard;
