import { fetch } from "./http";

const controller: string = "booking/";

export const carServicesService = async () => {
  try {
    const response = await fetch.Get(controller + "categories");

    if (response.status === 200) {
      const data = await response.json();
      if (!Array.isArray(data)) {
        return []
      }
      return data;
    } else if (response.status === 450) {
      const data = await response.json();
      throw new Error(data);
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error: any) {
    throw error;
  }
};

export const disabledSlotsService = async () => {
  try {
    const response = await fetch.Get(controller + "disabled_slots");

    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else if (response.status === 450) {
      const data = await response.json();
      throw new Error(data);
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error: any) {
    throw error;
  }
};


export const createOrderService = async (payload: any) => {
  try {
    const response = await fetch.Post(controller + "create", payload);

    if (response.status === 200 || response.status === 201) {
      const data = await response.json();
      return data;
    } else if (response.status === 450) {
      const data = await response.json();
      throw new Error(data);
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error: any) {
    throw error;
  }
};
