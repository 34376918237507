import { call, delay, put, takeLatest } from "redux-saga/effects";
import { CREATE_ORDER, FETCH_DISABLED_SLOTS, FETCH_SERVICES, INITIALIZE_PAYMENT } from "./types";
import { carServicesService, createOrderService, disabledSlotsService, initializePaymentService } from "../../services";
import { setDisabledSlots, setServices } from "./actions";
import { setBookingModel, setClientSecret } from "../additional";
import { removeLoader, setLoader } from "../loader";
import { setAlert, store } from "../";

function* handleServices(): Generator<any> {
  try {
    const responseData: any = yield call(carServicesService);
    yield put(setServices(responseData));
  } catch (error) { }
}

function* handleDisabledSlots(): Generator<any> {
  try {
    yield put(setLoader({ visible: true, transparent: true }))
    const responseData: any = yield call(disabledSlotsService);
    yield put(setDisabledSlots(responseData));
  } catch (error) { }
  finally {
    yield put(removeLoader())
  }
}

function* handleInitializePayment({ payload }: { payload: any }): Generator<any> {
  try {
    // yield put(setLoader({ visible: true, transparent: true }))
    const responseData: any = yield call(initializePaymentService, payload);
    yield put(setClientSecret(responseData));
  } catch (error) {
    yield put(setAlert({ text: error?.message, type: 'error' }))
  } finally {
    yield put(removeLoader())
  }
}

function* handleCreateOrder({ payload }: { payload: any }): Generator<any> {
  const model = store?.getState()?.bookingModel;
  try {
    yield put(setLoader({ visible: true, transparent: true }))
    const responseData: any = yield call(createOrderService, payload);
    if (model?.paymentType !== 'paynow') {
      yield put(setBookingModel({ ...model, ...payload, isConfirmed: true, status: responseData?.result?.status, bookingId: responseData?.result?.bookingId }));
    } else {
      yield put(setBookingModel({ ...model, ...payload, isConfirmed: false }));
    }

  } catch (error) {
    yield put(setAlert({ text: error?.message, type: 'error' }))
    yield put(setBookingModel({ ...model, ...payload, isConfirmed: false, status: "failed" }));
  } finally {
    yield delay(100)
    yield put(removeLoader())
  }
}

export function* bookingSaga() {
  yield takeLatest<any>(FETCH_SERVICES, handleServices);
  yield takeLatest<any>(FETCH_DISABLED_SLOTS, handleDisabledSlots);
  yield takeLatest<any>(INITIALIZE_PAYMENT, handleInitializePayment);
  yield takeLatest<any>(CREATE_ORDER, handleCreateOrder);
}
