import {
  Box,
  Container,
  Link,
  Stack,
  SxProps,
  Typography,
  useTheme,
} from "@mui/material";
import { Footerlinks, socialMedia, serviceslinks } from "./constants";
import Banner from "src/components/Banner";
import Logo from "src/components/LogoWhite";
import SectionHeader, { HeaderSpan } from "src/components/SectionHeader";
import { contactInfo } from "src/common/constants";
import { connect } from "react-redux";

const Column = ({ children, sx }: { children: any; sx?: SxProps }) => (
  <Box sx={{ width: { md: "23%", xs: "100%" }, mr: { md: 1, xs: 0 }, ...sx }}>
    {children}
  </Box>
);
const Footer = () => {
  const theme = useTheme();

  return (
    <Banner
      url="/assets/img/footer-bg.webp"
      colorSx={{
        backgroundImage: "unset",
        py: 2,
        my: 0,
      }}
      container={false}
    >
      {" "}
      <Container
        sx={{
          py: 2,
        }}
      >
        <Stack
          direction="row"
          sx={{
            display: { md: "flex", xs: "block" },
            justifyContent: "space-between",
          }}
        >
          <Column sx={{ width: { md: "35%" } }}>
            <Box
              component="ul"
              sx={{
                listStyle: "none",
                paddingLeft: 0,
                position: "relative",
              }}
            >
              <Logo width={150} />
              <Box
                component="ul"
                sx={{ listStyle: "none", mt: 1, paddingLeft: 0 }}
              >
                {contactInfo?.map((item, index) => {
                  let props = item?.value ? { href: item?.value } : {};
                  return (
                    <Box
                      key={index}
                      component="li"
                      sx={{
                        mb: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          color: theme?.colors?.primary.main,
                        }}
                      >
                        {item?.icon}
                        <Box
                          component={item?.value ? "a" : "span"}
                          sx={{ color: "white", textDecoration: "none" }}
                          {...props}
                        >
                          {item?.label}
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
              <Stack
                direction="row"
                spacing={1}
                component="ul"
                sx={{ listStyle: "none", paddingLeft: 0 }}
              >
                {socialMedia?.map((item, index) => {
                  return (
                    <Box
                      key={index}
                      component="li"
                      sx={{
                        display: "flex",
                        mb: 1,
                      }}
                    >
                      <Link
                        sx={{
                          color: theme?.colors.primary.main,
                          textDecoration: "none",
                        }}
                        href={item?.value}
                        target="_blank"
                      >
                        {item?.icon}
                      </Link>
                    </Box>
                  );
                })}
              </Stack>
            </Box>
          </Column>

          <Column sx={{ width: { md: "20%" }, mr: { md: 3, xs: 0 } }}>
            <SectionHeader
              sx={{
                color: "white",
                textAlign: "left",
                fontSize: 18,
                mb: 1,
                mt: 3,
              }}
            >
              <HeaderSpan>MENU</HeaderSpan>
            </SectionHeader>
            {Footerlinks?.map((item, index) => {
              return (
                <Box
                  key={index}
                  component="li"
                  sx={{
                    display: "flex",
                    mb: 1,
                  }}
                >
                  <Link
                    sx={{ color: "white", textDecoration: "none" }}
                    href={item?.value}
                  >
                    {item?.label}
                  </Link>
                </Box>
              );
            })}
          </Column>

          <Column sx={{ width: { md: "20%" }, mr: { md: 3, xs: 0 } }}>
            <SectionHeader
              sx={{
                color: "white",
                textAlign: "left",
                fontSize: 18,
                mb: 1,
                mt: 3,
              }}
            >
              <HeaderSpan>DETAILING SERVICES</HeaderSpan>
            </SectionHeader>
            {serviceslinks?.map((item, index) => {
              return (
                <Box
                  key={index}
                  component="li"
                  sx={{
                    display: "flex",
                    mb: 1,
                  }}
                >
                  <Link
                    sx={{ color: "white", textDecoration: "none" }}
                    href={item?.value}
                  >
                    {item?.label}
                  </Link>
                </Box>
              );
            })}
          </Column>
        </Stack>
      </Container>
      <Stack
        direction="row"
        justifyContent="center"
        pt={1}
        borderTop={1}
        borderColor="white"
      >
        <Typography sx={{ color: "white", mr: 0.5 }}>
          Copyright © {new Date()?.getFullYear()} | Powered by
        </Typography>
        <Link
          href="https://cyberace.ca"
          sx={{ textDecoration: "none", fontSize: 15 }}
          target="_blank"
        >
          Cyber Ace
        </Link>
      </Stack>
    </Banner>
  );
};
const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch: any) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Footer);
