import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Stack, Typography } from "@mui/material";
import Subscribe from "src/components/Subscribe";
import { Link } from "react-router-dom";
import GoogleReviews from "src/components/googleReviews";
import { Helmet } from "react-helmet";
import { carPolishing } from "src/components/FAQS/mockData";
import FAQItem from "src/components/FAQS";
class Index extends Component {
  state = {
    display: true,
    width: 300,
  };
  render() {
    const PrevArrow = (props) => {
      const { onClick } = props;
      return (
        <button
          className="carousel-control-prev"
          onClick={onClick}
          style={{
            left: "10px",
            zIndex: 1,
            backgroundColor: "#234DEF",
            width: "4%",
            borderRadius: "61%",
            height: "13%",
            margin: "auto",
          }}
        >
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="visually-hidden">Previous</span>
        </button>
      );
    };

    const NextArrow = (props) => {
      const { onClick } = props;
      return (
        <button
          className="carousel-control-next"
          onClick={onClick}
          style={{
            right: "10px",
            zIndex: 1,
            backgroundColor: "#234DEF",
            width: "4%",
            borderRadius: "61%",
            height: "13%",
            margin: "auto",
          }}
        >
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="visually-hidden">Next</span>
        </button>
      );
    };
    const slickSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,

      responsive: [
        {
          breakpoint: 850,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: false,
            nextArrow: false,
          },
        },
      ],
    };
    const slickSettings1 = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
      responsive: [
        {
          breakpoint: 900, // adjust the breakpoint as needed
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: false,
            nextArrow: false,
          },
        },
      ],
    };

    // Custom Next Arrow Component

    return (
      <div>
        <Helmet>
          <title>Car Polishing & Ceramic Coating Services in Calgary AB</title>
          <meta
            name="description"
            content="Get the best car polishing and ceramic coating services in Calgary AB at T-Bros Auto Detailing. Expert, affordable, and professional. Book your appointment now!"
          />
          <meta
            name="keywords"
            content="best car polishing services in calgary ab, ceramic coating services in calgary ab, exterior car polishing services in calgary ab, affordable auto polishing services in calgary ab, Professional ceramic coating services in calgary ab, vehicle polishing services in calgary ab"
          />
          <link
            id="canonical-tag-head"
            rel="canonical"
            href={window.location.href}
          />
        </Helmet>
        <section className="about-page about1 whychhose">
          {/* Top banner section */}
          <div className="carpolish">
            <div className="container">
              <div className="row">
                <div className="title-inner">
                  <h1 style={{ color: "var(--yellow)" }}>Car Polishing</h1>
                  <p>
                    Say goodbye to dull paint and hello to a <br />
                    beautiful ride. Have your car shining brighter than ever
                    before! <br />
                    Explore our affordable car polishing services in Calgary!{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <section className="two45">
            <div className="container">
              <div className="row w-100">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="card-why9">
                    <img
                      alt="car polishing calgary"
                      src="/assets/img/polish-firstfoldimg.png"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <p className="mt-6" style={{ textAlign: "left" }}>
                    WHO WE ARE
                  </p>
                  <h2 style={{ fontWeight: "bold", textAlign: "left" }}>
                    Get in and{" "}
                    <span style={{ color: "var(--blue)" }}>COME OUT</span> with
                    a<br />
                    nice{" "}
                    <span style={{ color: "var(--blue)" }}>
                      DETAILED CAR with the Best Car Polishing Services in
                      Calgary
                    </span>
                  </h2>
                  <p style={{ textAlign: "left", marginTop: 18 }}>
                    Nobody wants their beloved ride to look old and shabby.
                    That's why we offer the
                    <strong> best car polishing in Calgary,</strong>
                    lifting the appearance of your vehicle and boosting your
                    confidence inside and out!
                  </p>
                  <ul className="polish1">
                    <li>Same Day Service</li>
                    <li>Online Appointment</li>
                  </ul>
                  <ul className="polish1">
                    <li>Convenient Location</li>
                    <li>Complimentary Shuttle</li>
                  </ul>
                  <div
                    className="link-btn widhtbtn mt-3"
                    style={{ float: "left" }}
                  >
                    <Link to="/booking">MAKE AN APPOINTMENT</Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <section className="yellow-bg7 mt-6 py-5">
            <div className="container">
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <h2 style={{ fontWeight: "bold", textAlign: "left" }}>
                    About Our{" "}
                    <span style={{ color: "var(--blue)" }}>
                      Car Polishing Service in Calgary
                    </span>
                  </h2>
                  <p>
                    Car polishing is a technique that improves a vehicle's
                    exterior paint by removing imperfections and restoring
                    shine. It involves applying a specialized polish or compound
                    for a glossy, well-maintained finish.{" "}
                  </p>
                  <p>
                    Here is a detailed process of{" "}
                    <strong>car polishing in Calgary,</strong> that we follow to
                    transform your ride into a stunner:{" "}
                  </p>
                  <div className="video-text034">
                    <ul>
                      <li className="bullet">Surface Preparation</li>
                      <p>
                        We begin the process by preparing the exterior surface
                        of your vehicle, removing dirt, debris, and contaminants
                        to make the paint compounds work their way in.
                      </p>
                      <li className="bullet">Conceal</li>
                      <p>
                        {" "}
                        We then mask or cover certain parts of the vehicle such
                        as rubber trims or plastic parts that need to be
                        protected from the paint compounds.
                      </p>
                      <li className="bullet">Applying Compound</li>
                      <p>
                        The third step involves applying a polishing compound to
                        the surface of the vehicle to remove swirl marks and
                        other imperfections.
                      </p>
                      <li className="bullet">Polishing</li>
                      <p>
                        The next step that we follow during our process of
                        <strong>car polishing in Calgary,</strong> involves
                        applying a finer polishing compound that helps refine
                        the surface and restore its shine.
                      </p>
                      <li className="bullet">Protection</li>
                      <p>
                        To protect the polish and enhance its shine, a polish
                        protection paint is applied for a long-lasting outcome.
                      </p>
                      <li className="bullet">Buffing</li>
                      <p>
                        The surface of your freshly polished vehicle is then
                        buffed with a clean and soft microfibre to remove any
                        residue and reveal the final shine of the polished
                        paint.{" "}
                      </p>
                      <li className="bullet">Final Inspection</li>
                      <p>
                        The final process of our{" "}
                        <strong>car polishing services in Calgary</strong>{" "}
                        involves inspecting the vehicle carefully and ensuring
                        the desired results have been achieved.
                      </p>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-5 col-md-12 col-sm-12">
                  <div className="img-car4">
                    <img
                      className="img-fluid"
                      alt="ceramic coating Calgary"
                      src="/assets/img/carpolish1.webp"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section> */}

          <div className="container">
            <div className="row mb-2 mt-5 text-center bold-text">
              <h2>
                About Our{" "}
                <span style={{ color: "var(--blue)" }}>
                  {" "}
                  Affordable Auto Polishing Service in Calgary
                </span>
              </h2>
              <p>
                Car polishing services in Calgary involve a series of techniques
                that improve a vehicle's exterior paint by removing
                imperfections and restoring shine. It involves applying a
                specialized polish or compound for a glossy, well-maintained
                finish. Here is a detailed process of car polishing services in
                Calgary, that we follow to transform your ride into a stunner:
                Here is a detailed process of
                <strong> car polishing services in Calgary,</strong> that we
                follow to transform your ride into a stunner: <br />
              </p>
              <p></p>
            </div>
            <div className="row mt-4">
              <div className="col-lg-7 col-md-12 col-sm-12 mb-5">
                <div className="row tow89">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="card-box1 yellowbg">
                      <h4>1. Surface Preparation</h4>
                      <p>
                        We begin our Car polishing service process by preparing
                        the exterior surface of your vehicle, removing dirt,
                        debris, and contaminants to make the paint compounds
                        work their way in.
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="card-box1 bluebg1">
                      <h4>2. Conceal</h4>
                      <p>
                        The next process in car polishing in Calgary involves
                        covering certain parts of the vehicle such as rubber
                        trims or plastic parts that need to be protected from
                        the paint compounds.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="card-box1 bluebg1">
                      <h4>3. Applying Compound</h4>
                      <p>
                        The third step in car polishing in Calgary involves
                        applying a polishing compound to the surface of the
                        vehicle to remove swirl marks and other imperfections.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="card-box1 yellowbg">
                      <h4>4. Polishing</h4>
                      <p>
                        The next step that we follow during our process of car
                        polishing in Calgary, involves applying a finer
                        polishing compound that helps refine the surface and
                        restore its shine.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="card-box1 yellowbg">
                      <h4>5. Protection</h4>
                      <p>
                        To protect the polish and enhance its shine, a polish
                        protection paint is applied for a long-lasting outcome.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="card-box1 bluebg1">
                      <h4>6. Buffing</h4>
                      <p>
                        The surface of your freshly polished vehicle is then
                        buffed with a clean and soft microfibre to remove any
                        residue and reveal the final shine of the polished
                        paint.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="card-box1 img0">
                      <img
                        className="img-fluid"
                        src="/assets/img/image-gride.webp"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="card-box1 yellowbg">
                      <h4>7. Final Inspection</h4>
                      <p>
                        The final process of our car polishing services in
                        Calgary involves inspecting the vehicle carefully and
                        ensuring the desired results have been achieved.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-1 col-md-5 col-sm-5">
                <div className="row">
                  <div className="img-work1 mb-5">
                    <img src="/assets/img/carpolish1.webp" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="blue-bg7 mb-5">
            <div className="container">
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-5 col-md-12 col-sm-12">
                  <div className="img-car4">
                    <img
                      className="img-fluid"
                      alt="ceramic coating services in Calgary"
                      src="/assets/img/carpolish2.webp"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 color-white py-5">
                  <h2
                    style={{
                      fontWeight: "bold",
                      textAlign: "left",
                      color: "var(--white)",
                      paddingBottom: "10px",
                    }}
                  >
                    What Are Ceramic Coating Services in Calgary{" "}
                    <span style={{ color: "var(--yellow)" }}>
                      and How Does It Benefit Your Vehicle?
                    </span>
                  </h2>
                  <p>
                    Ceramic coating services in Calgary refer to a process where
                    a liquid polymer is applied to the exterior surface of the
                    vehicle to protect the paint. Apart from offering a glossy
                    finish to the vehicle, the ceramic coating services protect
                    the surface from corrosion, oxidation, and pollutants.
                  </p>
                  <p>
                    Here are some benefits of{" "}
                    <strong>ceramic coating services in Calgary:</strong>
                  </p>
                  <div className="video-text035">
                    <ol>
                      <li className="bullet1 mb-3">
                        The money you invest in our ceramic coating services in
                        Calgary is worth all the cost as it forms a protective
                        barrier against pollutants, dirt, road salt, and other
                        contaminants.
                      </li>
                      <li className="bullet1 mb-3">
                        {" "}
                        Professional Ceramic coating in Calgary offers
                        hydrophobic properties meaning that it repels water
                        making it easier for you to clean your car.
                      </li>
                      <li className="bullet1 mb-3">
                        Our ceramic coating services in Calgary offer an extra
                        layer of protection to your vehicle by forming a barrier
                        against light scratches and swirl marks.
                      </li>{" "}
                      <li className="bullet1 mb-3">
                        Apart from protecting your car’s surface, the
                        professional ceramic coating in Calgary gives your paint
                        a more reflective and deeper shine.
                      </li>
                      <li className="bullet1 mb-3">
                        Unlike other forms of paint protection methods that need
                        to be reapplied every few months, ceramic coating
                        services can last for years with proper maintenance.
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="container mt-4 p-0 slide-work">
            <div className="row larg-text mb-4  text-center">
              <h2>
                OUR WORK:{" "}
                <span style={{ color: "var(--blue)" }}>
                  {" "}
                  Professional Car Polishing Services in Calgary
                </span>
              </h2>
            </div>

            <Stack>
              <div className="work-img">
                <Slider {...slickSettings}>
                  <div className="img-work1">
                    <img src="/assets/img/ourwork01.webp" alt="Work 1" />
                  </div>
                  <div className="img-work1">
                    <img src="/assets/img/ourwork02.webp" alt="Work 2" />
                  </div>
                  <div className="img-work1">
                    <img src="/assets/img/ourwork03.webp" alt="Work 3" />
                  </div>
                  <div className="img-work1">
                    <img src="/assets/img/ourwork04.webp" alt="Work 4" />
                  </div>
                  <div className="img-work1">
                    <img src="/assets/img/ourwork05.webp" alt="Work 5" />
                  </div>
                </Slider>
              </div>
            </Stack>
          </div>
          <section className="call-to-action">
            <div className="container">
              <div className="row bg-yellow">
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <h2>Car Washing and Care Points</h2>
                </div>
                <div className="col-lg-4 col-md-12 cl-sm-12">
                  <p style={{ margin: 0 }}>
                    {" "}
                    <a href="tel:+1 587-999-8722;">
                      <i
                        className="bi bi-telephone-fill"
                        style={{ marginRight: 13 }}
                      />
                      +1 587-999-8722{" "}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </section>
          <div className="container">
            <Box sx={{ my: 10 }}>
              <Typography
                variant="h2"
                sx={{ display: "flex", justifyContent: "center", fontSize: 50 }}
                gutterBottom
              >
                FAQ’s
              </Typography>
              {carPolishing.map((carPolishing) => (
                <FAQItem
                  key={carPolishing.id}
                  question={carPolishing.question}
                  answer={carPolishing.answer}
                />
              ))}
            </Box>
          </div>
          <GoogleReviews />
          <Subscribe />
        </section>
      </div>
    );
  }
}

export default Index;
