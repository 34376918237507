import ReCAPTCHA from "react-google-recaptcha";
import { validationSchema, initialValues } from "./constants";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { submitContactUs } from "src/store";
import { connect } from "react-redux";
import { contactUsProps } from "src/interfaces";
import { useState } from "react";
import { Helmet } from "react-helmet";
const Component = ({
  submitContactUs,
}: {
  submitContactUs: (payload: contactUsProps) => void;
}) => {
  const handleSubmit = (values) => {
    if (!recaptcha) {
      alert("reCAPTCHA verification is required");
      return;
    }
    submitContactUs({
      ...values,
      callback: () => document.getElementById("reset-form")?.click(),
    });
  };
  const [recaptcha, setRecaptcha] = useState("");
  const handleRecaptchaChange = (value) => {
    setRecaptcha(value);
  };

  return (
    <div>
      <Helmet>
        <title>Contact Us</title>
        <link
            id="canonical-tag-head"
            rel="canonical"
            href={window.location.href}
          />
      </Helmet>
      <section className="about-page about1 whychhose">
        {/* Top banner section */}
        <div className="carpolish">
          <div className="container">
            <div className="row">
              <div className="title-inner">
                <h1 style={{ color: "var(--yellow)" }}>CONTACT Us</h1>
                <p>
                  Connect with us for expert car care! Questions,
                  <br /> appointments, or consultations—our
                  <br /> team is ready to assist. Your journey
                  <br /> to excellence begins here!
                </p>
              </div>
            </div>
          </div>
        </div>
        <section className="spce-contact">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="conta-box1">
                  <a href="https://maps.app.goo.gl/7aceKk9LDPdG4PEp6">
                    <div className="box-con">
                      <i className="bi bi-geo-alt-fill" />
                    </div>
                    <p className="title4">Address</p>
                    <p>
                      Unit# 3040 11124 36th Street Northeast, <br />
                      Calgary, AB T3N1L3
                    </p>
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="conta-box1">
                  <a href="mailto:tbros.information@gmail.com">
                    <div className="box-con">
                      <i className="bi bi-envelope" />
                    </div>
                    <p className="title4">Email ID</p>
                    <p>tbros.information@gmail.com</p>
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="conta-box1">
                  <a href="tel:+1 587-999-8722;">
                    <div className="box-con">
                      <i className="bi bi-telephone-fill" />
                    </div>
                    <p className="title4">Phone No</p>
                    <p>+1 587-999-8722</p>
                  </a>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mt-5">
              <div className="flex-row1">
                <div className="row">
                  <div className="col-lg-7 col-md-12 col-sm-12">
                    <div className="text-call-us">
                      <h3>LET US CALL YOU</h3>
                    </div>
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                      id="contact-form"
                    >
                      <Form className="form-input">
                        <div>
                          <Field
                            type="text"
                            name="name"
                            placeholder="Full Name"
                          />
                          <ErrorMessage name="name">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </div>

                        <div>
                          <Field
                            type="email"
                            name="email"
                            placeholder="Email ID"
                          />
                          <ErrorMessage name="email">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </div>

                        <div>
                          <Field
                            type="tel"
                            name="phone"
                            placeholder="Phone"
                            maxLength="14"
                          />
                          <ErrorMessage name="phone">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </div>

                        <div>
                          <Field
                            as="textarea"
                            name="message"
                            placeholder="Message ..."
                          />
                          <ErrorMessage name="message">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </div>
                        <Field
                          type="hidden"
                          name="recaptchaValue"
                          value={recaptcha}
                        />
                        <div className="mb-2">
                        <ReCAPTCHA
                          sitekey="6LcorWgpAAAAAFu04A7I_xL_N8NQRVDXax0GyD99"
                          onChange={handleRecaptchaChange}
                          onExpired={() => handleRecaptchaChange("")}
                          onErrored={() => handleRecaptchaChange("")}
                        />
                        </div>
                        <div>
                          <ErrorMessage name="recaptchaValue">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </div>
                        <div className="link-btn mb-2">
                          <button type="submit" className="btnform">
                            SUBMIT NOW
                          </button>
                          <button
                            type="reset"
                            id="reset-form"
                            style={{ display: "none" }}
                          />
                        </div>
                      </Form>
                    </Formik>
                  </div>
                  <div className="col-lg-5 col-md-12 col-sm-12 p-0">
                    <div className="addres1">
                      <h2 className="mb-3">Address</h2>
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2502.6209789919803!2d-113.98341772340112!3d51.15234017173473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x537161b7af4d6209%3A0x6d71fc5458089d08!2sT-Bros%20Auto%20Detailing!5e0!3m2!1sen!2sin!4v1702288304702!5m2!1sen!2sin"
                        width="100%"
                        height={400}
                        style={{ border: 0 }}
                        allowFullScreen
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                      />
                      <div className="space1 mt-3">
                        <div className="header-social">
                          <h3 className="flo4">Follow us</h3>
                          <a href="https://www.facebook.com/TBrosAutoDetailing/">
                            <i className="bi bi-facebook" />
                          </a>
                          <a href="https://www.instagram.com/tbrosautodetailing/">
                            <i className="bi bi-instagram" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  submitContactUs: (payload: any) => dispatch(submitContactUs(payload)),
});
export default connect(null, mapDispatchToProps)(Component);
