import { fetch } from "./http";

const controller: string = "payment/";

export const initializePaymentService = async (payload: { categoryId: string; serviceId: string; addons: string[]; totalPrice: number }) => {
  try {
    const response = await fetch.Post(controller + "initialize", payload);

    if (response.status === 200) {
      const data = await response.json();
      return data?.client_secret;
    } else if (response.status === 450) {
      const data = await response.json();
      throw new Error(data);
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error: any) {
    throw error;
  }
};

export const CreatePaymentSessionService = async (payload: {
  categoryId: string; serviceId: string; addons: any; totalPrice: number, date: string,
  slot: string, paymentType: string
}) => {
  try {
    const response = await fetch.Post(controller + "create-checkout-session", payload);

    if (response.status === 200) {
      const data = await response.json();
      return data
    } else if (response.status === 450) {
      const data = await response.json();
      console.log(data, ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>data");
      return data
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error: any) {
    throw error;
  }
};

export const confirmPaymentService = async (payload: { id: string, date: string, slot: string, paymentType: string }) => {
  try {
    const response = await fetch.Post(controller + "confirm-booking", payload);
    if (response.status === 200) {
      const data = await response.json();
      return data
    } else if (response.status === 450) {
      const data = await response.json();
      throw new Error(data);
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error: any) {
    throw error;
  }
};

export const CancelBookingService = async (payload: { id: string }) => {
  try {
    const response = await fetch.Post(controller + "cancel-booking", payload);
    if (response.status === 200) {
      const data = await response.json();
      return data
    } else if (response.status === 450) {
      const data = await response.json();
      throw new Error(data);
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error: any) {
    throw error;
  }
};
