import React, { Component } from "react";
import { Link } from "react-router-dom";
import Subscribe from "src/components/Subscribe";
import GoogleReviews from "src/components/googleReviews";
import { Helmet } from "react-helmet";
import FAQItem from "src/components/FAQS";
import { interiorDetailing } from "src/components/FAQS/mockData";
import { Box, Typography } from "@mui/material";
export class index extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>
            Expert car Interior Detailing in Calgary AB | T-Bros Auto Detailing
          </title>

          <meta
            name="description"
            content="Discover professional vinyl car interior detailing, tinting, and cleaning services in Calgary AB by T-Bros Auto Detailing. Schedule your appointment today!"
          />
          <meta
            name="keywords"
            content="Car interior detailing services in calgary ab, auto interior detailing services in calgary ab, vehicle window tinting services in calgary ab, Vinyl car interior cleaning services in calgary ab, expert car interior cleaning services in calgary ab, best Car interior detailing services in calgary ab, affordable auto interior detailing services in calgary ab, Professional vinyl car interior cleaning services in calgary ab"
          />
          <link
            id="canonical-tag-head"
            rel="canonical"
            href={window.location.href}
          />
        </Helmet>
        <section className="about-page Interior12 ">
          {/* Top banner section */}
          <div className="top-bg-inner">
            <div className="container">
              <div className="row">
                <div className="title-inner">
                  <p>Our Service</p>
                  <h1 style={{ color: "var(--yellow)" }}>
                    Professional Interior Detailing <br />
                    Services in Calgary{" "}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          {/*End  Top banner section */}
          <section className="image-with-heading">
            <div className="container">
              <div className="row mt-space">
                <div className="col-lg-4 col-md-5 col-sm-12">
                  <img
                    className="img-fluid"
                    alt="best interior detailing calgary"
                    src="/assets/img/img1.webp"
                  />
                </div>

                <div className="col-lg-8 col-md-7 col-sm-12 middle1">
                  <div className="container">
                    <div>
                      <h2 style={{ fontWeight: 600 }}>
                        Auto Interior Detailing Services in Calgary involves a{" "}
                        <span style={{ color: "#234DEF" }}>
                          {" "}
                          THOROUGH CLEANING
                        </span>{" "}
                        and restoration of your car's interior, addressing every
                        nook and cranny to <br />
                        ensure a{" "}
                        <span style={{ color: "#234DEF" }}>
                          {" "}
                          PRISTINE
                        </span> and{" "}
                        <span style={{ color: "#234DEF" }}>
                          COMFORTABLE ENVIRONMENT
                        </span>
                      </h2>
                      <div
                        className="link-btn widhtbtn"
                        style={{ marginTop: 30 }}
                      >
                        <Link to="/booking">CHECK OUR PACKAGES</Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bgimage1"></div>
              </div>
            </div>
          </section>
          <div className="detailing-bg mt-space">
            <div className="container">
              <div className="row mt-space">
                <div className="col-lg-6 col-md-12 col-sm-12 p-0">
                  <h2 style={{ color: "var(--white)", fontWeight: 500 }}>
                    Hand Wash And Dry With{" "}
                    <span style={{ color: "#FCF003" }}>
                      <br /> Premium shampoo{" "}
                    </span>
                    <span style={{ color: "var(--white)" }}>
                      and <br />
                    </span>
                    <span style={{ color: "#FCF003" }}>Non–Scratch Rugs</span>
                  </h2>
                  <p className="p-white">
                    Renew the appearance and texture of your seats, mats, and
                    carpets through our expert interior detailing services in
                    Calgary. Revel in the newfound transparency of your windows
                    and mirrors, expertly cleaned to enhance your satisfaction.
                    Our professionals improve the overall look of your car with
                    an expert vacuuming session that covers both the floor and
                    cargo area, ensuring it regains its original appeal.
                    <br />
                    Trust our auto detailing services in Calgary to bring out
                    the best in your vehicle's interior, providing a thorough
                    and rejuvenating service that leaves it looking and feeling
                    brand new.
                  </p>
                  <div className="link-btn widhtbtn  mt-5">
                    <Link to="/booking">MAKE AN APPOINTMENT</Link>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 p-0">
                  <img
                    className="img-fluid"
                    alt="cheap interior car wash"
                    src="/assets/img/interiorimg3.webp"
                    data-aos="zoom-out-up"
                  />
                </div>
              </div>
            </div>
          </div>
          <section className="about11 mt-6 p-text-fonts">
            <div className="container">
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-7 col-md-12 col-sm-12 mb-3">
                  <img
                    className="imgwidth"
                    alt="best window tint in Calgary"
                    src="/assets/img/interiorimg2.webp"
                    data-aos="fade-right"
                  />
                </div>
                <div className="col-lg-5 col-md-12 col-sm-12">
                  <h2 style={{ fontWeight: 600 }}>
                    Experience the{" "}
                    <span style={{ color: "#234DEF" }}>
                      Best Interior Detailing Services:{" "}
                    </span>
                    Cleaning and Conditioning for All Vinyl and Leather Surfaces
                  </h2>
                  <br />
                  <p>
                    Experience superior interior detailing in Calgary with our
                    professional service, expertly wiping clean all vinyl and
                    leather surfaces. Our experts ensure precision and care,
                    using specialized techniques and products to enhance the
                    appearance and longevity of your vehicle's interior. From
                    thorough cleaning to conditioning, our auto detailing
                    services in Calgary provide an effective solution for
                    maintaining the best condition of your car's vinyl and
                    leather surfaces, leaving you with a polished and
                    professional driving experience.
                  </p>
                  <br />
                  <h2 style={{ fontWeight: 600 }}>
                    Window <span style={{ color: "#234DEF" }}>Tinting </span>
                  </h2>
                  <br />
                  <p>
                    Enhances privacy, reduces glare, and protects interiors from
                    harmful UV rays. Elevate your driving experience with
                    stylish and functional window tinting for a sleek,
                    comfortable ride with the best interior detailing services
                    in Calgary.
                  </p>
                  <div className="link-btn widhtbtn mt-5">
                    <Link to="/booking">MAKE AN APPOINTMENT</Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="gride-video12">
            <div className="video-ply mt-6" data-aos="fade-up">
              <div className="video-wrapper">
                <video autoPlay muted playsInline preload="auto">
                  <source src="/assets/img/t-brosvideo.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="container">
              <div className="row w-100">
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="card-box yellowbg" data-aos="flip-right">
                    <div className="svg-img">
                      <img
                        src="/assets/img/interior1.webp"
                        data-aos="fade-right"
                      />
                    </div>
                    <h4>
                      Dry Vacuum all seats, mats, carpet, trunk and clean all
                      corners with pressure air
                    </h4>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="card-box bluebg1" data-aos="flip-right">
                    <div className="svg-img">
                      <img
                        src="/assets/img/interior2.webp"
                        data-aos="fade-right"
                      />
                    </div>
                    <h4>
                      Clean windows and glass inside out with streak proof
                      ammonia cleaner
                    </h4>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="card-box yellowbg" data-aos="flip-right">
                    <div className="svg-img">
                      <img
                        src="/assets/img/interior3.webp"
                        data-aos="fade-right"
                      />
                    </div>
                    <h4>
                      Enhances privacy, reduces glare, and
                      <br />
                      protects interiors from
                      <br />
                      harmful UV rays.
                    </h4>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="card-box bluebg1" data-aos="flip-right">
                    <div className="svg-img">
                      <img
                        src="/assets/img/interior4.webp"
                        data-aos="fade-right"
                      />
                    </div>
                    <h4>
                      Clear door jams and
                      <br /> grease
                    </h4>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="card-box yellowbg" data-aos="flip-right">
                    <div className="svg-img">
                      <img
                        src="/assets/img/interior5.webp"
                        data-aos="fade-right"
                      />
                    </div>
                    <h4>Carpets and mats shampoo wash</h4>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="card-box bluebg1" data-aos="flip-right">
                    <div className="svg-img">
                      <img
                        src="/assets/img/interior6.webp"
                        data-aos="fade-right"
                      />
                    </div>
                    <h4>
                      Dress all interior vinyl surface and rubber <br /> mats
                    </h4>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="card-box yellowbg" data-aos="flip-right">
                    <div className="svg-img">
                      <img
                        src="/assets/img/interior7.webp"
                        data-aos="fade-right"
                      />
                    </div>
                    <h4>
                      Deodorize interior <br />
                      and A/C vents
                    </h4>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="card-box bluebg1" data-aos="flip-right">
                    <div className="svg-img">
                      <img
                        src="/assets/img/interior8.webp"
                        data-aos="fade-right"
                      />
                    </div>
                    <h4>
                      Seats shampoo/ <br />
                      leather condition and dress
                    </h4>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="card-box yellowbg" data-aos="flip-right">
                    <div className="svg-img">
                      <img
                        src="/assets/img/interior9.webp"
                        data-aos="fade-right"
                      />
                    </div>
                    <h4>Headliner spot clean</h4>
                  </div>
                </div>
              </div>
              <div className="link-btn widhtbtn m-auto mb-10">
                <Link to="/booking">MAKE AN APPOINTMENT</Link>
              </div>
            </div>
          </section>
          <div className="container">
            <Box sx={{ my: 10 }}>
              <Typography
                variant="h2"
                sx={{ display: "flex", justifyContent: "center", fontSize: 50 }}
                gutterBottom
              >
                FAQ’s
              </Typography>
              {interiorDetailing.map((interiorDetailing) => (
                <FAQItem
                  key={interiorDetailing.id}
                  question={interiorDetailing.question}
                  answer={interiorDetailing.answer}
                />
              ))}
            </Box>
          </div>
          <GoogleReviews />
          <Subscribe />
        </section>
      </div>
    );
  }
}

export default index;
