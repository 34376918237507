import { call, put, takeLatest } from "redux-saga/effects";
import {
  HANDLE_ADD_DISABLED_SLOT,
  HANDLE_DELETE_DISABLED_SLOT,
  HANDLE_LOAD_All_DISABLED_SLOTS,
  HANDLE_LOAD_All_SERVICES,
  HANDLE_LOAD_BOOKINGS,
  HANDLE_UPDATE_ADDON_PRICE,
  HANDLE_UPDATE_BOOKING_STATUS,
  HANDLE_UPDATE_DISABLED_SLOT,
  HANDLE_UPDATE_SERVICE_PRICE,
} from "./types";
import {
  addDisabledSlotService,
  deleteDisabledSlotService,
  loadAllDisabledSlotsService,
  loadBookingsService,
  loadCategoriesService,
  updateAddOnPriceService,
  updateBookingStatusService,
  updateDisabledSlotService,
  updateServicePriceService,
} from "../../services";
import { setAlert } from "../alert";
import { removeLoader, setLoader } from "../loader";
import { setAllDisabledSlots, setAllServices, setBookings } from "./actions";

function* handleLoadBookings(): Generator<any> {
  yield put(setLoader({ transparent: true }));
  try {
    const responseData: any = yield call(loadBookingsService);
    yield put(setBookings(responseData));
  } catch (error) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleUpdateBookingStatus({ payload }: { payload: any }): Generator<any> {
  yield put(setLoader({ transparent: true }));
  try {
    const responseData: any = yield call(updateBookingStatusService, payload);
    yield handleLoadBookings();
  } catch (error) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleLoadAllServices(): Generator<any> {
  yield put(setLoader({ transparent: true }));
  try {
    const responseData: any = yield call(loadCategoriesService);
    yield put(setAllServices(responseData));
  } catch (error) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleUpdateService({ payload }: { payload: any }): Generator<any> {
  yield put(setLoader({ transparent: true }));
  try {
    const responseData: any = yield call(updateServicePriceService, payload);
    yield handleLoadAllServices();
  } catch (error) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleUpdateAddOn({ payload }: { payload: any }): Generator<any> {
  yield put(setLoader({ transparent: true }));
  try {
    const responseData: any = yield call(updateAddOnPriceService, payload);
    yield handleLoadAllServices();
  } catch (error) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleLoadAllDisabledSlots(): Generator<any> {
  yield put(setLoader({ transparent: true }));
  try {
    const responseData: any = yield call(loadAllDisabledSlotsService);
    yield put(setAllDisabledSlots(responseData));
  } catch (error) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleAddDisabledSlot({ payload }: { payload: any }): Generator<any> {
  yield put(setLoader({ transparent: true }));
  try {
    const responseData: any = yield call(addDisabledSlotService, payload);
    yield handleLoadAllDisabledSlots();
  } catch (error) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleUpdateDisabledSlot({ payload }: { payload: any }): Generator<any> {
  yield put(setLoader({ transparent: true }));
  try {
    const responseData: any = yield call(updateDisabledSlotService, payload);
    yield handleLoadAllDisabledSlots();
  } catch (error) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

function* handleDeleteDisabledSlot({ payload }: { payload: any }): Generator<any> {
  yield put(setLoader({ transparent: true }));
  try {
    const responseData: any = yield call(deleteDisabledSlotService, payload?.id);
    yield handleLoadAllDisabledSlots();
  } catch (error) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  yield put(removeLoader());
}

export function* adminSaga() {
  yield takeLatest<any>(HANDLE_LOAD_BOOKINGS, handleLoadBookings);
  yield takeLatest<any>(HANDLE_UPDATE_BOOKING_STATUS, handleUpdateBookingStatus);
  yield takeLatest<any>(HANDLE_LOAD_All_SERVICES, handleLoadAllServices);
  yield takeLatest<any>(HANDLE_UPDATE_SERVICE_PRICE, handleUpdateService);
  yield takeLatest<any>(HANDLE_UPDATE_ADDON_PRICE, handleUpdateAddOn);
  yield takeLatest<any>(HANDLE_LOAD_All_DISABLED_SLOTS, handleLoadAllDisabledSlots);
  yield takeLatest<any>(HANDLE_ADD_DISABLED_SLOT, handleAddDisabledSlot);
  yield takeLatest<any>(HANDLE_UPDATE_DISABLED_SLOT, handleUpdateDisabledSlot);
  yield takeLatest<any>(HANDLE_DELETE_DISABLED_SLOT, handleDeleteDisabledSlot);
}
