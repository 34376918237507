export const interiorDetailing = [
  {
    id: 1,
    question:
      "Is there any difference between interior detailing services and exterior detailing services in Calgary?",
    answer:
      "While interior detailing in Calgary fixes everything inside of your car including vacuuming, shampooing, and conditioning upholstery, carpets, and surfaces, exterior detailing focuses on taking care of the outer surface including polishing the surfaces, paintwork, removing scratches, and beautifying the overall appearance of the outer body.",
  },
  {
    id: 2,
    question:
      "Can interior detailing services increase the resale value of my car?",
    answer:
      "Yes, when you opt for the best interior car detailing in Calgary, it can help improve the resale value of your car. A well-maintained and neat car indicates that it has been cared for, resulting in a positive first impression. Buyers generally pay more for a well-kept car.",
  },
  {
    id: 3,
    question:
      "Are interior detailing services in Calgary safe for all types of cars? ",
    answer:
      "Our interior detailing in Calgary is safe for all types of car interiors. Our experts use only the proper and specific products and techniques tailored to specific interiors and car types.",
  },

  {
    id: 4,
    question: "How much does auto car detailing in Calgary usually cost? ",
    answer:
      "The cost of interior car detailing in Calgary can vary depending on several factors, including the size of the vehicle, its condition, the location, and the level of service provided. On average, interior detailing costs less for standard cars, while larger vehicles or those requiring extensive cleaning may cost more.",
  },

  {
    id: 5,
    question: "Will interior detailing remove stains and odors from my car?",
    answer:
      "Our interior detailing in Calgary is safe for all types of car interiors. Our experts use only the proper and specific products and techniques tailored to specific interiors and car types.",
  },
];

export const exteriorDetailing = [
  {
    id: 1,
    question:
      "Is vehicle exterior detailing in Calgary safe for my vehicle's paint?",
    answer:
      "Yes, our professional auto detailing experts in Calgary use specialized techniques and products to safeguard the exterior of your car’s surface throughout the process.",
  },
  {
    id: 2,
    question:
      "Can exterior detailing services in Calgary help with scratches and swirl marks? ",
    answer:
      "Our exterior detailing services in Calgary help remove swirl marks and minor scratches perfectly. However, deep scratches and damage might require additional treatment such as paint correction services.",
  },
  {
    id: 3,
    question:
      "How long does the process of exterior detailing services usually take? ",
    answer:
      "The process of exterior detailing services in Calgary depends on several factors such as the condition of the vehicle, the method used, and any additional service requests.",
  },
  {
    id: 4,
    question: "What does the exterior detailing services package include?",
    answer:
      "Exterior detailing in Calgary typically includes washing, drying, polishing, and waxing in Calgary, to enhance the appearance of a vehicle and protect it from environmental damage.",
  },
  {
    id: 5,
    question:
      "What is the difference between a car wash and auto exterior detailing in Calgary?",
    answer:
      "A car wash is a quick process that cleans the exterior of the vehicle whereas exterior detailing services in Calgary include a comprehensive process including a deep cleaning process, polishing, painting, and protecting the outer surface of the vehicle.",
  },
];

export const touchUpPaint = [
  {
    id: 1,
    question:
      "Can I do touch-up paint myself, or should I hire a professional?",
    answer:
      "Hiring a professional auto detailing company in Calgary is a better option as experts provide a higher quality finish and guarantee of the service.",
  },
  {
    id: 2,
    question: "Will the touch-up paint match my car's real color?",
    answer:
      "Our professional car detailing in Calgary involves using computerized color-matching technology to resemble the real paint of your car. ",
  },
  {
    id: 3,
    question:
      "Will touch-up paint services in Calgary help last the paint longer?",
    answer:
      "Professionally applied touch-up paint in Calgary lasts for years and only requires seasonal touch-ups to maintain its appearance.",
  },
  {
    id: 4,
    question: "Is touch-up paint resistant to severe weather? ",
    answer:
      "Our experts only use high-quality touch-up paint products that provide severe weather resistance.",
  },
];

export const undercoatingRust = [
  {
    id: 1,
    question: "What is undercoating, and why is it important?",
    answer:
      "Vehicle undercoating in Calgary is a process that involves applying a protective layer to the underside of the vehicle to protect against corrosion and rust by exposure to different environmental hazards or moisture. ",
  },
  {
    id: 2,
    question: "Does undercoating work on existing rust spots?",
    answer:
      "Vehicle Undercoating Services in Calgary help the surface from corroding further. Proper rust removal and treatment are necessary before applying undercoating to ensure protection.",
  },
  {
    id: 3,
    question:
      "How long do the effects of vehicle undercoating and rust protection services last?",
    answer:
      "It depends on several factors such as the type of product used, driving habits, and maintenance practices. Undercoating in Calgary usually remains for a couple of years and regular touch-ups and maintenance can improve the lifespan of the undercoat.",
  },
  {
    id: 4,
    question: "When should undercoating be applied to the vehicle?",
    answer:
      "Vehicle undercoating in Calgary should be done on both new and relatively older vehicles especially if they're regularly exposed to harsh weather conditions or road salt.",
  },
];
export const carPolishing = [
  {
    id: 1,
    question:
      "Why should I opt for professional car polishing services in Calgary? ",
    answer:
      "Car polishing services in Calgary involve maintaining the appearance of your car and protecting its paintwork. The process helps to restore the gloss, removes contaminants, and enhances the overall look of your vehicle.",
  },
  {
    id: 2,
    question: "How often should I go for polishing services for my car? ",
    answer:
      "As a general guideline, it's recommended to go for professional car polishing services for your car every 4-6 months for optimal results.",
  },
  {
    id: 3,
    question: "Can professional car polishing remove scratches from my car? ",
    answer:
      "Yes, professional car polishing in Calgary effectively removes light scratches and swirl marks. However, deeper scratches might require additional services.",
  },
  {
    id: 4,
    question: "How long will the results of car polishing services last? ",
    answer:
      "The results of vehicle polishing in Calgary depend on several factors such as driving conditions, maintenance practices, and environmental exposure. With proper care, the effects of car polishing can last several months.",
  },
];

export const headlightRestoration = [
  {
    id: 1,
    question: "Is headlight restoration worth the cost?",
    answer:
      "Headlight restoration services in Calgary play a vital role in improving the safety and visibility factor of your headlights.",
  },
  {
    id: 2,
    question: "Can I do headlight restoration myself?",
    answer:
      "Professional headlight restoration services in Calgary are a better option as experts possess the required skills and knowledge needed for the best results.",
  },
  {
    id: 3,
    question: "How long does headlight restoration take?",
    answer:
      "Headlight restoration services in Calgary may take anywhere between 30 minutes to a few hours based on several different factors.",
  },

  {
    id: 3,
    question: "Why are my headlights becoming yellow and cloudy with time?",
    answer:
      "There are several factors contributing to this issue. These include prolonged exposure to sunlight, oxidation, UV rays, and environmental pollutants.",
  },
];
