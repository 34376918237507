import { useEffect } from "react";
import { useRoutes, useNavigate, useLocation } from "react-router-dom";
import router from "src/router";
import { connect } from "react-redux";
import { CssBaseline, useTheme } from "@mui/material";
import { authUserProps, routeProps } from "./interfaces";
import Snackbar from "./components/Snackbar";
import {  removeRoute } from "./store";
import { Helmet } from "react-helmet-async";
import Spinner from "./components/Spinner";

function AppContent({
  auth,
  route,
  removeRoute,
}: {
  auth: authUserProps;
  route: routeProps;
  removeRoute: Function;
}) {
  const content = useRoutes(router(auth?.role, auth?.isLoggedIn));
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  useEffect(() => {
    if (route?.path) {
      window.scrollTo(0, 0);
      navigate(route?.path, { state: route.params });
      removeRoute();
    }
  }, [navigate, route]);

  const pathname = location?.pathname;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Helmet>
        <meta name="theme-color" content={theme?.colors?.secondary?.main} />
      </Helmet>
      <Spinner />
      <Snackbar />
      <CssBaseline />
      {content}
    </>
  );
}
const mapStateToProps = ({ auth, route }) => ({ auth, route });
const mapDispatchToProps = (dispatch) => ({
  removeRoute: () => dispatch(removeRoute()),
});
export default connect(mapStateToProps, mapDispatchToProps)(AppContent);