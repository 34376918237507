import React, { useState } from "react";
import Subscribe from "src/components/Subscribe";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import CallSection from "src/reusables/callSection";
import { sections } from "./data";
import { Button, Stack } from "@mui/material";
import Modal from "src/components/Modal";
import { submitAppointment } from "src/store";
import { Formik, Field, Form, ErrorMessage } from "formik";
import {
  validationSchema,
  initialValues,
  validationSchema2,
  initialValues2,
} from "../home/constants";
import CallIcon from "@mui/icons-material/Call";
import { makeAnAppointmentProps } from "src/interfaces";
import { connect } from "react-redux";

const Index = ({ submitAppointment }) => {
  const [open, setOpen] = useState(false);
  const [showQuoteText, setShowQuoteText] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    setShowQuoteText(false);
  };

  const handleClose = () => {
    setOpen(false);
    setShowQuoteText(false);
  };

  const handleOpenQuoteButton = () => {
    setOpen(true);
    setShowQuoteText(true);
  };

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    submitAppointment({
      ...values,
      callback: () => {
        setSubmitting(false);
      },
    });
  };

  return (
    <div>
      <Helmet>
        <title>
          Paint Protection Film Installers in Calgary - T-Bros Auto Detailing
        </title>
        <meta
          name="description"
          content="Protect your car with XPEL PPF Calgary experts! Trust T-Bros Auto Detailing for the finest paint protection film installation service. Call +1 587-999-8722."
        />
        <meta name="keywords" content="" />
        <link
          id="canonical-tag-head"
          rel="canonical"
          href={window.location.href}
        />
      </Helmet>
      <section className="about-page about1 whychhose">
        {/* Top banner section */}
        <section className="top-section2">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-lg-6 col-md-5 col-sm-12">
                <div className="banner-text">
                  <h2 className="h2">30% Off!</h2>
                  <h3 className="">on all our detailing PACKAGES</h3>
                  <h1 className="h2"> Paint Protection Films that Shield!</h1>
                  <h3 className="">
                    Give your ride the ultimate protection with superior paint
                    protection films that retain its showroom brilliance.
                  </h3>
                  <div
                    className="d-flex justify-content-left
                     align-items-center gap-4 mt-5"
                  >
                    <div className="fs-3 text-white font-weight-500 ">
                      Powered By
                    </div>
                    <img
                      className="w-25"
                      src="/assets/img/xpel.png"
                      alt="Xpel"
                    />
                  </div>
                </div>
              </div>
            </div>
            <Button
              onClick={handleOpen}
              variant="contained"
              sx={{
                borderRadius: "5px",
                padding: "10px",
                paddingLeft: "40px",
                paddingRight: "40px",
                marginTop: "30px",
              }}
            >
              Get Your Quote
            </Button>
            <div>
              <Stack>
                <div className="bg-dark w-50">
                  <Modal
                    backgroundColor="#234DEF"
                    width={500}
                    open={open}
                    onClose={handleClose}
                  >
                    <div className=" ">
                      <Formik
                        initialValues={initialValues2}
                        validationSchema={validationSchema2}
                        onSubmit={handleSubmit}
                      >
                        <Form className="form-input m-4">
                          {showQuoteText && (
                            <>
                              <div
                                style={{
                                  color: "white",
                                  marginTop: "20px",
                                  marginBottom: "20px",
                                  fontSize: "20px",
                                }}
                              ></div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  border: "solid 2px  yellow",
                                  padding: "10px",
                                  alignItems: "center",
                                }}
                              >
                                <a
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 6,
                                    color: "yellow",
                                    fontSize: "20px ",
                                    fontWeight: "bold",
                                  }}
                                  href="tel:+1 587-999-8722"
                                >
                                  {" "}
                                  <CallIcon /> Call +1 587-999-8722{" "}
                                </a>
                              </div>
                              <div
                                style={{
                                  color: "white",
                                  marginTop: "20px",
                                  marginBottom: "20px",
                                  fontWeight: "bold",
                                  fontSize: "20px",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                Or
                              </div>
                            </>
                          )}
                          <Field type="text" name="name" placeholder="Name" />

                          <div style={{ height: "18px" }}>
                            <ErrorMessage
                              name="name"
                              component="div"
                              className="homeError"
                            />
                          </div>

                          <Field
                            as="select"
                            name="service"
                            className="sected45"
                          >
                            <option value="Paint Protection Film">
                              Paint Protection Film
                            </option>
                          </Field>

                          <div style={{ height: "18px" }} />
                          <Field
                            type="number"
                            name="phone"
                            maxLength="14"
                            placeholder="Phone"
                          />
                          <div style={{ height: "18px" }}>
                            <ErrorMessage
                              name="phone"
                              component="div"
                              className="homeError "
                            />
                          </div>
                          <div className="">
                            <Field
                              as="textarea"
                              name="message"
                              placeholder="Message ..."
                            />
                          </div>

                          <div className="link-btn ">
                            <button type="submit" className="btnform mb-4">
                              Get Your Quote
                            </button>
                          </div>
                        </Form>
                      </Formik>
                    </div>
                  </Modal>
                </div>
              </Stack>
            </div>
          </div>
        </section>
        <section className="spce-contact1 mt-6">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <h2 style={{ fontWeight: 600 }}>
                  Protect Your Vehicle with the{" "}
                  <span style={{ color: "#234DEF" }}>
                    Highest Quality Paint Protection Films in Calgary
                  </span>{" "}
                </h2>
                <p className="mt-3">
                  Protect your vehicle’s long-term showroom look and shine with
                  our expert paint protection films. Designed to offer a
                  protective barrier against corrosion, bird droppings,
                  scratches, and chips, our paint protection films are the right
                  choice for keeping your ride brand new.
                </p>
                <div onClick={handleOpen} className="link-btn widhtbtn2 mt-5 ">
                  <Link to="">Get Your Quote</Link>
                </div>
              </div>

              <div className="col-lg-6 col-md-12 col-sm-12 mt-5">
                <img
                  className="imgwidth aos-init aos-animate"
                  src="/assets/img/protection.png"
                  data-aos="fade-right"
                  alt="Paint Protection"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="whychoose4 touch-paint1">
          <div className="container-fluid p-0 mt-6">
            <div className="bg-blue">
              <div className="row mt-5 align-items-center">
                <div className="col-lg-6 col-md-12 col-sm-12 bgchose">
                  <img
                    className="img-fluid"
                    alt="car paint chip repair"
                    src="/assets/img/side425.png"
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="row mb-2 mt-3 ">
                    <div className="col-md-8">
                      <h2>
                        <span style={{ color: "var(--white)" }}>
                          How Does It Work?
                          <br />
                        </span>
                      </h2>
                      <p className="mt-4">
                        Paint Protection Films or PPF works by providing a
                        long-lasting, protective barrier that shields the paint
                        of the vehicle from potential damage. The paint
                        protection film is made from thermoplastic urethane,
                        which helps absorb impacts from road debris, preventing
                        chips and scratches. It also features self-healing
                        properties, allowing minor scratches to disappear with
                        heat. Our paint protection film helps block UV rays and
                        prevents stains from bird droppings, tree sap, and paint
                        fading. Our auto detailing experts in Calgary apply this
                        paint protection film to fit specific vehicle models and
                        install it using a solution ensuring a smooth finish.
                      </p>
                      <div
                        onClick={handleOpen}
                        className="link-btn widhtbtn2 mt-5"
                      >
                        <Link to="">Get Your Quote</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="paint-protection">
          <div className="mb-5 text-center">
            <h2>
              <span
                style={{
                  color: "var(--blue)",
                  fontSize: 50,
                  fontWeight: 700,
                }}
              >
                Service
              </span>{" "}
              <span style={{ fontSize: 50, fontWeight: 700 }}>List</span>{" "}
            </h2>
            <div style={{ display: "flex ", justifyContent: "center" }}>
              <Button

                onClick={handleOpenQuoteButton}
                variant="contained"
                sx={{
                  borderRadius: "5px",
                  padding: "10px",
                  paddingLeft: "40px",
                  paddingRight: "40px",
                  marginTop: "30px",
                }}
              >
                Call for quote
              </Button>
            </div>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              {sections.map((section, index) => (
                <div key={index} className={section.className}>
                  <div
                    className={`card-box3 ${section.bgClass}`}

                  >
                    <div className={section.pClass}>{section.description}</div>
                    <div className="d-flex justify-content-end align-items-center gap-2">


                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className=" d-flex justify-content-center">
              <div className="col-lg-12  col-xl-12 col-md-9 col-sm-12 col-12">
                <div
                  className="card-box3 bluebg1 color-white d-flex justify-content-center"
          
                  data-aos-offset="500"
                  data-aos-duration="500"
                >
                  {/* <div className="d-flex justify-content-end textp3 font-size-3 align-items-center color-white text3 ">
                    $3999
                  </div> */}
                  <div className="p3 font-size-3 ">Full Car Wrap</div>
                  <div className="d-flex justify-content-end align-items-center gap-2 ">
                    <div className="box">
                      {/* <div className="boxBorder px-2 yellowbg p-1">
                        with 30 % off
                      </div> */}
                    </div>
                    {/* <div className="text3 font-size-20">$2799.30</div> */}
                    {/* <Button
                      onClick={handleOpenQuoteButton}
                      variant="contained"
                      sx={{
                        borderRadius: "5px",
                        padding: "10px",
                        paddingLeft: "40px",
                        paddingRight: "40px",
                        marginTop: "30px",
                      }}
                    >
                      Call for the quote
                    </Button> */}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: "flex ", justifyContent: "center" }}>
              <Button

                onClick={handleOpenQuoteButton}
                variant="contained"
                sx={{
                  borderRadius: "5px",
                  padding: "10px",
                  paddingLeft: "40px",
                  paddingRight: "40px",
                  marginTop: "30px",
                }}
              >
                Call for quote
              </Button>
            </div>
          </div>


        </section>
        <CallSection />
        <Subscribe />
      </section>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  submitAppointment: (payload) => dispatch(submitAppointment(payload)),
});

export default connect(null, mapDispatchToProps)(Index);
