import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router } from "react-router-dom";
import { SidebarProvider } from "src/contexts/SidebarContext";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ReduxProvider from "./store/config";
import ThemeProvider from "./theme/ThemeProvider";
import "moment-timezone";
import moment from "moment";
import { useEffect } from "react";
// Load the required plugins

const defaualtTimeZone = process.env.REACT_APP_TIME_ZONE;

function AppProviders({ children }) {
  useEffect(() => {
    moment.tz.setDefault(defaualtTimeZone);
  }, []);

  return (
    <HelmetProvider>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ReduxProvider>
            <SidebarProvider>
              <Router>{children}</Router>
            </SidebarProvider>
          </ReduxProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
}
export default AppProviders;