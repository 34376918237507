import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Stack } from "@mui/material";
import Subscribe from "src/components/Subscribe";
import GoogleReviews from "src/components/googleReviews";
import { Helmet } from "react-helmet";
class Index extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Why Choose Us</title>
          <link
            id="canonical-tag-head"
            rel="canonical"
            href={window.location.href}
          />
        </Helmet>
        <section className="about-page about1 whychhose">
          {/* Top banner section */}
          <div className="whychhose1">
            <div className="container">
              <div className="row">
                <div className="title-inner">
                  <h1 style={{ color: "var(--yellow)" }}>Why Choose Us</h1>
                  <p>
                    Choose us for precision detailing, personalized service,
                    <br /> lasting protection, and unparalleled <br />
                    attention to your vehicle's needs.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <section className="straight mt-6">
            <div className="container">
              <div className="row">
                <h4 className="bgh4">Car wash &amp; detailing service</h4>
                <h2 style={{ fontWeight: 600 }}>
                  We Are
                  <span style={{ color: "var(--blue)" }}>
                    {" "}
                    Innovative{" "}
                  </span>And{" "}
                  <span style={{ color: "var(--blue)" }}>
                    Passionate
                    <br />
                  </span>
                  About The Work We Do
                </h2>
              </div>
              <div className="row gride-ime5">
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <div className="row why5">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="card-why">
                        <div className="row over-icon">
                          <div className="col-4">
                            <img src="/assets/img/iconwhy01.svg" />
                          </div>
                          <div className="col-8">
                            <p className="sub-text">Attention</p>
                            <h3>
                              to{" "}
                              <span style={{ color: "var(--blue)" }}>
                                Detail
                              </span>
                            </h3>
                          </div>
                        </div>
                        <div className="bg-blue9 bluebg1">
                          <p>
                            We believe that the little things make a big
                            difference. That's why we pay close attention to
                            every detail of your car, from the wheels to the
                            roof, to ensure it looks flawless when you drive
                            away.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="card-why">
                        <div className="row over-icon">
                          <div className="col-4">
                            <img src="/assets/img/iconwhy02.svg" />
                          </div>
                          <div className="col-8">
                            <p className="sub-text">Years of</p>
                            <h3>
                              <span style={{ color: "var(--blue)" }}>
                                Experience
                              </span>
                            </h3>
                          </div>
                        </div>
                        <div className="bg-blue9 yellowbg">
                          <p>
                            With years of experience in the industry, we know
                            how to treat every type of vehicle with care and
                            attention to detail. Our technicians are skilled in
                            using the latest techniques and equipment to get the
                            job done right.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt5">
                      <div className="card-why">
                        <div className="row over-icon">
                          <div className="col-4">
                            <img src="/assets/img/iconwhy03.svg" />
                          </div>
                          <div className="col-8">
                            <p className="sub-text">Professional</p>
                            <h3>
                              <span style={{ color: "var(--blue)" }}>Team</span>
                            </h3>
                          </div>
                        </div>
                        <div className="bg-blue9 yellowbg">
                          <p>
                            Our team of experts is dedicated to delivering
                            top-notch service and ensuring your vehicle looks
                            its best. We take pride in our work and always aim
                            to exceed your expectations.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt5">
                      <div className="card-why">
                        <div className="row over-icon">
                          <div className="col-4">
                            <img src="/assets/img/iconwhy04.svg" />
                          </div>
                          <div className="col-8">
                            <p className="sub-text">Quality</p>
                            <h3>
                              <span style={{ color: "var(--blue)" }}>
                                Product
                              </span>
                            </h3>
                          </div>
                        </div>
                        <div className="bg-blue9 bluebg1">
                          <p>
                            We use only the best products on your vehicle,
                            ensuring that your car gets the best possible care.
                            From shampoos and polishes to waxes and sealants, we
                            use only the finest products to give your vehicle a
                            showroom shine.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <img className="img-fluid " src="/assets/img/img56.webp" />
                </div>
              </div>
            </div>
          </section>
          <section className="video-row mt-6 video-text3">
            <div className="container-fuild">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="overhiden">
                    <video
                      autoPlay
                      muted
                      loop={true}
                      playsInline
                      preload="metadata"
                    >
                      <source
                        src="/assets/img/t-brosvideo.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 middle25">
                  <div className="video-text03">
                    <h2 style={{ fontWeight: 600, color: "#fff" }}>
                      Opt For Our
                      <span style={{ color: "#FCF003" }}>
                        {" "}
                        Services And Receive…
                      </span>
                    </h2>
                    <div className="row check-cion45 mt-4">
                      <div className="col-1">
                        <img src="/assets/img/check-icon.svg" />
                      </div>
                      <div className="col-10">
                        <h4>Showroom Shine</h4>
                        <p>
                          Elevate your ride with our precision detailing,
                          restoring its showroom brilliance.
                        </p>
                      </div>
                    </div>
                    <div className="row check-cion45">
                      <div className="col-1">
                        <img src="/assets/img/check-icon.svg" />
                      </div>
                      <div className="col-10">
                        <h4>Personalized Care</h4>
                        <p>
                          Tailored service, addressing your vehicle's unique
                          needs with finesse.
                        </p>
                      </div>
                    </div>
                    <div className="row check-cion45">
                      <div className="col-1">
                        <img src="/assets/img/check-icon.svg" />
                      </div>
                      <div className="col-10">
                        <h4>Lasting Protection</h4>
                        <p>
                          Enduring beauty with our advanced techniques,
                          providing long-term preservation for your vehicle.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="call-to-action">
            <div className="container">
              <div className="row bg-yellow">
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <h2>Car Washing and Care Points</h2>
                </div>
                <div className="col-lg-4 col-md-12 cl-sm-12">
                  <p style={{ margin: 0 }}>
                    {" "}
                    <a href="tel:+1 587-999-8722;">
                      <i
                        className="bi bi-telephone-fill"
                        style={{ marginRight: 13 }}
                      />
                      +1 587-999-8722{" "}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </section>
          <GoogleReviews />
          <Subscribe />
        </section>
      </div>
    );
  }
}

export default Index;
