import { Box, Container, SxProps } from "@mui/material";

const Banner = ({
  children,
  imageSx,
  colorSx,
  containerSx,
  url,
  container = true,
}: {
  children: any;
  imageSx?: SxProps;
  colorSx?: SxProps;
  containerSx?: SxProps;
  url?: { md: string; xs: string } | string;
  container?: boolean;
}) => {
  let bgUrl: any = `url(/assets/img/headerImg.webp)`;
  if (url) {
    if (typeof url === "object") {
      bgUrl = {
        md: `url(${url?.md})`,
        xs: `url(${url?.xs})`,
      };
    } else {
      bgUrl = `url(${url})`;
    }
  }
  return (
    <Box
      component="div"
      sx={{
        backgroundImage: bgUrl,
        backgroundSize: "cover",
        width: "100%",
        ...imageSx,
      }}
    >
      <Box
        sx={{
          backgroundImage: "linear-gradient(#073662d6, #07366280)",
          py: 8,
          width: "100%",
          height: "100%",
          ...colorSx,
        }}
      >
        {container ? (
          <Container maxWidth="lg" sx={{ ...containerSx }}>
            {children}
          </Container>
        ) : (
          children
        )}
      </Box>
    </Box>
  );
};

export default Banner;
