import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/material"; 

const FAQItem = ({ question, answer }) => {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <Accordion
      onChange={(_, status) => {
        setExpanded(status);
      }}
      sx={{
        borderWidth: "2px !important",
        borderBottom: "solid",
        borderBottomColor: "#234DEF",
        borderBottomLeftRadius: "0 !important",
        borderBottomRightRadius: "0 !important",
      }}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            sx={{
              color: "#234DEF",
              fontSize: { xs: 30, md: 50, sx: 40 },
              transform: expanded ? "rotate(-180deg)" : "rotate(-90deg)",
              transition: "transform 0.1s ease-in-out",
            }}
          />
        }
        aria-controls={`panel-content`}
        id={`panel-header`}
        sx={{
          fontSize: { xs: 15, md: 30, sx: 20 },
          fontWeight: { xs: 300, md: 500, sx: 300 },
          paddingX: "0!important",
        }}
      >
        <Box sx={{ maxWidth: {md:"100%", xs:"30%" }, height: "auto", display:"flex", alignItems:"center", justifyContent:"left" ,}}>
          <Box
            component="img"
            src="/assets/img/faq-symbol.png"
            alt="FAQ Symbol"
            sx={{height: {md:"100%", xs:"60%" } }}
          />
        </Box>

        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "left ",
            ml: 2,
          }}
        >
          {question}
        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          marginLeft: { xs: 10, md: 18, sx: 10 },
          backgroundColor: "#F4F4F4",
          padding: { xs: 2, md: 5, sx: 3 },
          fontSize: { xs: 10, md: 18, sx: 10 },
          fontWeight: 500,
          color: "#585858",
          mb: 2,
        }}
      >
        {answer}
      </AccordionDetails>
    </Accordion>
  );
};

export default FAQItem;
