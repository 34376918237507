import type { PayloadAction } from "@reduxjs/toolkit";
import {
  RESET_BOOKING_MODEL,
  SET_BOOKING_MODEL,
  SET_CLIENT_SECRET,
  SET_GOOGLE_REVIEWS,
} from "./types";
import { stripeProps, reviewsRoot, bookingModelProps } from "src/interfaces";
import { reviews } from "src/mockData/reviews";
import { dateParser } from "src/helpers";

export const googleReviews = (
  state: reviewsRoot = reviews,
  action: PayloadAction<any> = null
): reviewsRoot => {
  const type = action?.type;
  const payload = action?.payload;
  switch (type) {
    case SET_GOOGLE_REVIEWS: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

export const stripeModel = (
  state: stripeProps = { clientSecret: null },
  action: PayloadAction<any> = null
): stripeProps => {
  const type = action?.type;
  const payload = action?.payload;
  switch (type) {
    case SET_CLIENT_SECRET: {
      return { ...state, clientSecret: payload };
    }
    default: {
      return state;
    }
  }
};

export const bookingModel = (
  state: bookingModelProps = {},
  action: PayloadAction<bookingModelProps> = null
): bookingModelProps => {
  const type = action?.type;
  const payload = action?.payload;
  switch (type) {
    case SET_BOOKING_MODEL: {
      return { ...payload, paymentType: payload?.paymentType || "paynow", date: payload?.date || dateParser(new Date()).format("YYYY-MM-DD") };
    }
    case RESET_BOOKING_MODEL: {
      return { paymentType: 'paynow', date: dateParser(new Date()).format("YYYY-MM-DD") };
    }
    default: {
      return state;
    }
  }
};
