import { call, put, takeLatest } from "redux-saga/effects";
import { contactUsProps, makeAnAppointmentProps } from "../../interfaces";
import { FETCH_GOOGLE_REVIEWS, SUBMIT_APPOINTMENT_FORM, SUBMIT_CONTACT_FORM } from "./types";
import { contactUsService, makeAnAppointmentService} from "../../services";
import { setGoogleReview } from "./actions";
import { reviews } from "src/mockData/reviews";
import { setAlert } from "../alert";
import { removeLoader, setLoader } from "../loader";

function* handleGoogleReviews(): Generator<any> {
  try {
    // const responseData: any = yield call(googleReviewsService);
    yield put(setGoogleReview(reviews));
  } catch (error) { }
}

function* handleSubmitContactUs({ payload }: { payload: contactUsProps }): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(contactUsService, payload);
    yield put(setAlert({ text: "Mail sent succcessfully, we will contact you shortly.", type: "success" }));
  } catch (error) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  finally {
    yield put(removeLoader());
  }
}
function* handleSubmitAppointment({ payload }: { payload: makeAnAppointmentProps }): Generator<any> {
  try {
    yield put(setLoader({ transparent: true }));
    const responseData: any = yield call(makeAnAppointmentService, payload);
    yield put(setAlert({ text: "Thanks for showing your interest, we will contact you shortly.", type: "success" }));
  } catch (error) {
    yield put(setAlert({ text: error?.message, type: "error" }));
  }
  finally {
    yield put(removeLoader());
  }
}

export function* additionalSaga() {
  yield takeLatest<any>(FETCH_GOOGLE_REVIEWS, handleGoogleReviews);
  yield takeLatest<any>(SUBMIT_CONTACT_FORM, handleSubmitContactUs);
  yield takeLatest<any>(SUBMIT_APPOINTMENT_FORM, handleSubmitAppointment);

}
