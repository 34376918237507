import {
  Box,
  Rating,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import Card from "../../components/Card";

import SectionHeader, {
  HeaderSpan,
  SectionHeaderTop,
} from "src/components/SectionHeader";
import SectionContainer from "src/components/SectionContainer";
import Banner from "src/components/Banner";
import { reducerProps } from "src/interfaces";
import { fetchGoogleReview } from "src/store";
import { connect } from "react-redux";
import { useEffect } from "react";
import { reviewsRoot } from "src/interfaces";
import StarIcon from "@mui/icons-material/Star";

const GoogleReviews = ({
  banner,
  fetchGoogleReview,
  googleReviews,
}: {
  banner?: string;
  fetchGoogleReview: Function;
  googleReviews?: reviewsRoot;
}) => {
  const theme = useTheme();
  const reviews = googleReviews?.result?.reviews;
  useEffect(() => {
    fetchGoogleReview();
  }, []);

  return (
    <SectionContainer
      sx={{
        mt: 0,
        mb: 0,
      }}
    >
      <Banner
        url={banner || "/assets/img/reviews-bg.webp"}
        colorSx={{ backgroundImage: "unset" }}
      >
        <SectionHeaderTop
          sx={{ textAlign: "center", color: theme?.colors?.alpha.white[100] }}
        >
          Few Compliments
        </SectionHeaderTop>
        <SectionHeader
          sx={{
            textAlign: "center",
            color: theme?.colors?.alpha.white[100],
            mb: 0.5,
          }}
        >
          Our <HeaderSpan>Testimonials</HeaderSpan>
        </SectionHeader>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          mb={2}
          spacing={1}
        >
          <Typography
            sx={{ fontSize: 18 }}
            color={theme?.colors?.alpha.white[100]}
          >
            {googleReviews?.result?.rating}.0
          </Typography>
          <StarIcon
            color="primary"
            fontSize="medium"
            sx={{ top: -1.5, position: "relative" }}
          />
          <Typography
            component="a"
            color={theme?.colors?.alpha.white[100]}
            href={googleReviews?.result?.url}
            sx={{ textDecoration: "none" }}
            target="_blank"
          >
            ({googleReviews?.result?.user_ratings_total}+ reviews)
          </Typography>
        </Stack>
        <Stack
          direction={{ md: "row", xs: "column" }}
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          {reviews
            ?.filter((g) => g.rating >= 4)
            ?.slice(0, 4)
            ?.map((item, index: number) => (
              <Card
                key={index}
                imgSx={{
                  display: "none",
                }}
                sx={{
                  mb: {
                    md: 0,
                    xs: "2rem !important",
                  },
                  boxShadow: "unset",
                  width: { md: "30%" },
                  height: { md: 260 },
                  padding: { xs: 1.8, md: 1.6 },
                  backgroundColor: "unset",
                }}
                title={item?.author_name}
                titleSx={{
                  textAlign: "start",
                  textTransform: "capitalize",
                  color: theme?.colors?.alpha?.white[100],
                }}
                description={
                  <Box
                    sx={{
                      color: theme?.colors?.alpha?.white[100],
                    }}
                  >
                    <Rating
                      value={item?.rating}
                      readOnly
                      sx={{ color: theme?.colors?.primary?.main }}
                    />
                    <Tooltip
                      sx={{ display: { md: "block", xs: "none" } }}
                      title={item?.text}
                    >
                      <Typography
                        sx={{ color: theme?.colors?.alpha.white[70] }}
                        component="p"
                        title={item?.text}
                      >
                        {`${item?.text.substring(0, 308)}${
                          item?.text.length > 308 ? "..." : ""
                        }`}
                      </Typography>
                    </Tooltip>
                    <Typography
                      sx={{
                        display: {
                          xs: "block",
                          md: "none",
                          color: theme?.colors?.alpha.white[70],
                        },
                      }}
                      component="p"
                      title={item?.text}
                    >
                      {`${item?.text.substring(0, 475)}${
                        item?.text.length > 475 ? "..." : ""
                      }`}
                    </Typography>
                  </Box>
                }
                descriptionSx={{
                  textAlign: "start",
                }}
              />
            ))}
        </Stack>
      </Banner>
    </SectionContainer>
  );
};

const mapStateToProps = ({ googleReviews }: reducerProps) => ({
  googleReviews,
});
const mapDispatchToProps = (dispatch) => ({
  fetchGoogleReview: () => dispatch(fetchGoogleReview()),
});
export default connect(mapStateToProps, mapDispatchToProps)(GoogleReviews);
