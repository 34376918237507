import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from '@mui/icons-material/LocationOn';

export const contactInfo = [
  {
    icon: <PhoneIcon sx={{ color: "primary", mr: 1 }} />,
    label: "+1 587-999-8722",
    value: "tel:+1 587-999-8722",
  },
  {
    icon: <LocationOnIcon sx={{ color: "primary", mr: 1 }} />,
    value: 'https://maps.app.goo.gl/rcc1HyuzCHyH2NCg8',
    label: "Unit# 3040 11124 36th Street Northeast, Calgary, AB T3N1L3",
  },
];
