import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Stack, Typography } from "@mui/material";
import Subscribe from "src/components/Subscribe";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { touchUpPaint } from "src/components/FAQS/mockData";
import FAQItem from "src/components/FAQS";
export class index extends Component {
  render() {
    const PrevArrow = (props) => {
      const { onClick } = props;
      return (
        <button
          className="carousel-control-prev"
          onClick={onClick}
          style={{
            left: "10px",
            zIndex: 1,
            backgroundColor: "#234DEF",
            width: "5%",
            borderRadius: "61%",
            height: "15%",
            margin: "auto",
          }}
        >
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="visually-hidden">Previous</span>
        </button>
      );
    };

    const NextArrow = (props) => {
      const { onClick } = props;
      return (
        <button
          className="carousel-control-next"
          onClick={onClick}
          style={{
            right: "10px",
            zIndex: 1,
            backgroundColor: "#234DEF",
            width: "5%",
            borderRadius: "61%",
            height: "15%",
            margin: "auto",
          }}
        >
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="visually-hidden">Next</span>
        </button>
      );
    };

    const WorkPrevArrow = (props) => {
      const { onClick } = props;
      return (
        <button
          className="carousel-control-prev"
          onClick={onClick}
          style={{
            left: "10px",
            zIndex: 1,
            backgroundColor: "#234DEF",
            width: "10%",
            borderRadius: "61%",
            height: "5%",
            margin: "auto",
          }}
        >
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="visually-hidden">Previous</span>
        </button>
      );
    };

    const WorkNextArrow = (props) => {
      const { onClick } = props;
      return (
        <button
          className="carousel-control-next"
          onClick={onClick}
          style={{
            right: "10px",
            zIndex: 1,
            backgroundColor: "#234DEF",
            width: "10%",
            borderRadius: "61%",
            height: "5%",
            margin: "auto",
          }}
        >
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="visually-hidden">Next</span>
        </button>
      );
    };
    const slickSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
      responsive: [
        {
          breakpoint: 850,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: false,
            nextArrow: false,
          },
        },
      ],
    };
    const slickSettings1 = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      prevArrow: <WorkPrevArrow />,
      nextArrow: <WorkNextArrow />,
      responsive: [
        {
          breakpoint: 900, // adjust the breakpoint as needed
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: false,
            nextArrow: false,
          },
        },
      ],
    };

    return (
      <div>
        <Helmet>
          <title>Best Car Touch-Up Paint & Repair in Calgary AB</title>
          <meta
            name="description"
            content="T-Bros Auto Detailing offers expert car touch-up paint, scratch & dent repair, bumper repair, and paint correction services in Calgary AB. Contact us today!"
          />
          <meta
            name="keywords"
            content="best car Touch-Up Paint services in calgary ab, Scratches & Dent Repair services in calgary ab, Paint Matching services in calgary ab, Bumper Scraps & cracks repair services in calgary ab, Paint Correction services in calgary ab, Professional vehicle Touch-Up Paint services in calgary ab"
          />
          <link
            id="canonical-tag-head"
            rel="canonical"
            href={window.location.href}
          />
        </Helmet>
        <section className="about-page about1 whychhose">
          {/* Top banner section */}
          <div className="touch-paint">
            <div className="container">
              <div className="row">
                <div className="title-inner">
                  <h1 style={{ color: "var(--yellow)" }}>Touch-Up Paint</h1>
                  <p>
                    Make Imperfections Vanish in Minutes! Explore the Magic of
                    The
                    <br />
                    <strong>
                      Best Touch-Up Paint Services in Calgary
                    </strong>{" "}
                    Today!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row mb-2 mt-5 text-center bold-text">
              <h2>
                What We <span style={{ color: "var(--blue)" }}> Do? </span>
              </h2>
              <p>
                Transforming rides into a love affair with expertise, precision,
                and care.
                <br />
                The art of{" "}
                <strong> paint correction services in Calgary,</strong> by the
                very best!
              </p>
            </div>
            <div className="row icon-svg mt-5 align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="row">
                  <div className="col-6">
                    <div className="row text-center">
                      <div className="col-12">
                        <img src="/assets/img/scratches.svg" />
                      </div>
                      <div className="col-12">
                        <h3>
                          Scratches &amp; Dent <br />
                          Repair
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row text-center">
                      <div className="col-12">
                        <img src="/assets/img/bumper.svg" />
                      </div>
                      <div className="col-12">
                        <h3>
                          Bumper Scraps <br />
                          and Cracks
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row text-center">
                      <div className="col-12">
                        <img src="/assets/img/fixing.svg" />
                      </div>
                      <div className="col-12">
                        <h3>
                          Fixing <br />
                          Rust
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row text-center">
                      <div className="col-12">
                        <img src="/assets/img/paint.svg" />
                      </div>
                      <div className="col-12">
                        <h3>
                          Paint <br />
                          Matching
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <img className="img-fluid" src="/assets/img/toch-img.webp" />
              </div>
            </div>
          </div>
          <section className="whychoose4 touch-paint1">
            <div className="container-fluid p-0 mt-6">
              <div className="bg-blue">
                <div className="row mt-5 align-items-center">
                  <div className="col-lg-6 col-md-12 col-sm-12 bgchose">
                    <img
                      className="img-fluid"
                      alt="car paint chip repair"
                      src="/assets/img/side425.webp"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="row mb-2 mt-3 ">
                      <div className="col-md-8">
                        <h2>
                          <span style={{ color: "var(--white)" }}>
                            {" "}
                            Paint Correction Services in Calgary vs <br />
                            Touch-Up Paint Pen
                          </span>
                        </h2>
                        <p className="mt-4">
                          Touch-Up Paint Pen{" "}
                          <strong>touch-up paint services in Calgary</strong>{" "}
                          are two different approaches used to correct
                          imperfections or damages to the vehicle’s paint on the
                          outer surface. Professional Paint correction services
                          in Calgary are a process that helps remove
                          imperfections, ensuring a flawless, glossy finish. Our
                          touch-up paint pen is your solution for targeted
                          touch-ups, resolution to minor chips and scratches,
                          and making your car look its best with precision and
                          ease.
                        </p>
                        <div className="link-btn widhtbtn mt-4">
                          <Link to="/booking">MAKE AN APPOINTMENT</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="container">
            <div className="row mb-2 mt-5 text-center bold-text">
              <h2>
                Our <span style={{ color: "var(--blue)" }}> Process</span>
              </h2>
              <p>
                Refining vehicles with a meticulous process for lasting
                brilliance and protection. Experience the best touch-up paint
                services in Calgary!
              </p>
            </div>
            <div className="row mt-4">
              <div className="col-lg-7 col-md-12 col-sm-12">
                <div className="row tow89">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="card-box yellowbg">
                      <div className="svg-img">
                        <img src="/assets/img/touch-up-paint1.webp" />
                      </div>
                      <h4>1. Clean the Area</h4>
                      <p>
                        Before beginning the touch-up paint process, we wash the
                        affected area. By using quality car wash soap and clean
                        microfiber towels to ensure no damage to the car.
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="card-box bluebg1">
                      <div className="svg-img">
                        <img src="/assets/img/touch-up-paint2.webp" />
                      </div>
                      <h4>2. Clear the Dirt</h4>
                      <p>
                        The next step in our touch-up paint service in Calgary
                        involves using the abrasive tip of the pen or grit paper
                        we carefully clean out the chip. This ensures a clean
                        surface for work, and avoids scratches on the paint,
                        minimizing additional tasks.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="card-box bluebg1">
                      <div className="svg-img">
                        <img src="/assets/img/touch-up-paint3.webp" />
                      </div>
                      <h4>3. Apply the Paint</h4>
                      <p>
                        During our paint correction services in Calgary, we use
                        our specialized correction pen and carefully apply the
                        paint, starting from the top to let it blend into the
                        chip. We start with a small amount for optimal
                        precision, ensuring a flawlessly restored finish.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="card-box yellowbg">
                      <div className="svg-img">
                        <img src="/assets/img/touch-up-paint4.webp" />
                      </div>
                      <h4>4. Drying the Paint</h4>
                      <p>
                        After applying the first coat of paint to the scratch,
                        we let it air dry for some time.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="card-box img0">
                      <img
                        className="img-fluid"
                        src="/assets/img/image-gride.webp"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mb-5">
                    <div className="card-box bluebg1">
                      <div className="svg-img">
                        <img src="/assets/img/touch-up-paint5.webp" />
                      </div>
                      <h4>5. Apply Clear Coat</h4>
                      <p>
                        We then apply a thin layer of clear coat to this freshly
                        painted area in order to protect it from sun exposure
                        and future weather damage.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 col-sm-12">
                <div className="row">
                  <div className="afterbefore">
                    <Slider {...slickSettings1}>
                      <div className="img-work1">
                        <img src="/assets/img/slidera-b1.webp" />
                      </div>
                      <div className="img-work1">
                        <img src="/assets/img/slidera-b1.webp" />
                      </div>
                      <div className="img-work1">
                        <img src="/assets/img/slidera-b1.webp" />
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="blue-bg7 mb-5 ">
            <div className="container">
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-5 col-md-12 col-sm-12">
                  <div className="img-car4">
                    <img
                      className="img-fluid rounded"
                      alt="ceramic coating services in Calgary"
                      src="/assets/img/carpolish5.png"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 color-white py-5">
                  <h2
                    style={{
                      fontWeight: "bold",
                      textAlign: "left",
                      color: "var(--white)",
                      paddingBottom: "10px",
                    }}
                  >
                    Explore the Benefits of Our{" "}
                    <span style={{ color: "var(--yellow)" }}>
                      Paint Correction Services in Calgary
                    </span>
                  </h2>
                  <p>
                    Our paint protection and touch-up paint services in Calgary
                    ensure that your car leaves with a showroom finish and
                    appeal. Here are some benefits of opting for our services:
                  </p>
                  <div className="video-text035">
                    <ol>
                      <li className="bullet1 mb-3">
                        Our{" "}
                        <strong>paint correction services in Calgary </strong>{" "}
                        do not break the bank as they prevent minor scratches
                        from turning into larger damages at affordable prices.
                      </li>
                      <li className="bullet1 mb-3">
                        {" "}
                        The overall process of touch-up paint services in
                        Calgary protects the car’s exterior from corrosion and
                        rust by effectively sealing the exposed metal.
                      </li>
                      <li className="bullet1 mb-3">
                        The <strong>touch-up paint services in Calgary </strong>{" "}
                        are beneficial for those looking to sell their vehicles
                        by restoring their showroom finish and aesthetic appeal.
                      </li>{" "}
                      <li className="bullet1 mb-3">
                        Our paint correction services in Calgary are suitable
                        for various surfaces, including metal, plastic, and
                        composite materials.
                      </li>
                      <li className="bullet1 mb-3">
                        A major benefit of touch-up paint in Calgary is that it
                        dries quickly resulting in minimum downtime. This is
                        suitable for those with busy schedules.
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="container p-0 slide-work">
            <div className="row mb-2 text-center bold-text">
              <h2 className="mt-5">
                OUR <span style={{ color: "var(--blue)" }}> WORK</span>
              </h2>
            </div>
            <Stack>
              <div className="work-img">
                <Slider {...slickSettings}>
                  <div className="img-work1">
                    <img src="/assets/img/ourwork01.webp" alt="Work 1" />
                  </div>
                  <div className="img-work1">
                    <img src="/assets/img/ourwork02.webp" alt="Work 2" />
                  </div>
                  <div className="img-work1">
                    <img src="/assets/img/ourwork03.webp" alt="Work 3" />
                  </div>
                  <div className="img-work1">
                    <img src="/assets/img/ourwork04.webp" alt="Work 4" />
                  </div>
                  <div className="img-work1">
                    <img src="/assets/img/ourwork05.webp" alt="Work 5" />
                  </div>
                </Slider>
              </div>
            </Stack>
          </div>
          <section className="call-to-action">
            <div className="container">
              <div className="row bg-yellow">
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <h2>Car Washing and Care Points</h2>
                </div>
                <div className="col-lg-4 col-md-12 cl-sm-12">
                  <p style={{ margin: 0 }}>
                    {" "}
                    <a href="tel:+1 587-999-8722;">
                      <i
                        className="bi bi-telephone-fill"
                        style={{ marginRight: 13 }}
                      />
                      +1 587-999-8722{" "}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </section>
          <div className="container">
            <Box sx={{ my: 10 }}>
              <Typography
                variant="h2"
                sx={{ display: "flex", justifyContent: "center", fontSize: 50 }}
                gutterBottom
              >
                FAQ’s
              </Typography>
              {touchUpPaint.map((touchUpPaint) => (
                <FAQItem
                  key={touchUpPaint.id}
                  question={touchUpPaint.question}
                  answer={touchUpPaint.answer}
                />
              ))}
            </Box>
          </div>
          <Subscribe />
        </section>
      </div>
    );
  }
}

export default index;
