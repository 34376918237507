export const sections = [
  {
    className: "col-lg-6 col-md-9 col-sm-12",
    bgClass: "bluebg1 color-white",
    dataAos: "zoom-in-down",
    priceText: "$349",
    description: "Standard Kit-18 Inch Hood, Fender, Mirror",
    discountText: "with 30% off",
    discountPrice: "$244.30",
    textClass: "text3",
    textP: "textp2",
    pClass: "p3",
    bg: "yellowbg",
  },
  {
    className: "col-lg-6 col-md-9 col-sm-12",
    bgClass: "yellowbg",
    dataAos: "zoom-in-down",
    priceText: "$199",
    description: "Splash Area (Strip behind 4 wheels )",
    discountText: "with 30% off",
    discountPrice: "$139.30",
    textClass: "text2",
    pClass: "p2",
    bgtext: "bgtext",
    textP: "textp3",
    bg: "bluebg1 ",
  },
  {
    className: "col-lg-6 col-md-9 col-sm-12",
    bgClass: "bluebg1 color-white",
    dataAos: "zoom-in-down",
    priceText: "$399",
    description: "Standard Kit-24 Inch Hood, Fender, Mirror",
    discountText: "with 30% off",
    discountPrice: "$279.30",
    textClass: "text3",
    textP: "textp2",
    pClass: "p3",
    bg: "yellowbg",
  },
  {
    className: "col-lg-6 col-md-9 col-sm-12",
    bgClass: "yellowbg",
    dataAos: "zoom-in-down",
    priceText: "$149",
    description: "Grill",
    discountText: "with 30% off",
    discountPrice: "$104.30",
    textClass: "text2",
    textP: "textp3",
    pClass: "p2",
    bgtext: "bgtext",
    bg: "bluebg1 ",
  },

  {
    className: "col-lg-6 col-md-9 col-sm-12",
    bgClass: "bluebg1 color-white",
    dataAos: "zoom-in-down",
    priceText: "$449",
    description: "Deluxe Kit-18 Inch Hood, Fender, Mirror, Bumper",
    discountText: "with 30% off",
    discountPrice: "$314.30",
    textClass: "text3",
    textP: "textp2",
    pClass: "p3",
    bg: "yellowbg",
  },

  {
    className: "col-lg-6 col-md-9 col-sm-12",
    bgClass: "yellowbg",
    dataAos: "zoom-in-down",
    priceText: "$449",
    description: "Rocker Panels",
    discountText: "with 30% off",
    discountPrice: "$314.30",
    textClass: "text2",
    pClass: "p2",
    bgtext: "bgtext",
    textP: "textp3",
    bg: "bluebg1 ",
  },

  {
    className: "col-lg-6 col-md-9 col-sm-12",
    bgClass: "bluebg1 color-white",
    dataAos: "zoom-in-down",
    priceText: "$799",
    description: "Deluxe Kit-24 Inch Hood, Fender, Mirror, Bumper",
    discountText: "with 30% off",
    discountPrice: "$559.30",
    textClass: "text3",
    pClass: "p3",
    textP: "textp2",
    bg: "yellowbg",
  },

  {
    className: "col-lg-6 col-md-9 col-sm-12",
    bgClass: "yellowbg",
    dataAos: "zoom-in-down",
    priceText: "$149",
    description: "Door Cups (2 door)",
    discountText: "with 30% off",
    discountPrice: "$104.30",
    textClass: "text2",
    pClass: "p2",
    bgtext: "bgtext",
    textP: "textp3",
    bg: "bluebg1 ",
  },

  {
    className: "col-lg-6 col-md-9 col-sm-12",
    bgClass: "bluebg1 color-white",
    dataAos: "zoom-in-down",
    priceText: "$1499",
    description: "Supreme-Full Hood, Full Fender ,B, M, HL",
    discountText: "with 30% off",
    discountPrice: "$1049.30",
    textClass: "text3",
    pClass: "p3",
    textP: "textp2",
    bg: "yellowbg",
  },

  {
    className: "col-lg-6 col-md-9 col-sm-12",
    bgClass: "yellowbg",
    dataAos: "zoom-in-down",
    priceText: "$199",
    description: "Door Cups (4 door)",
    discountText: "with 30% off",
    discountPrice: "$139.30",
    textClass: "text2",
    pClass: "p2",
    bgtext: "bgtext",
    textP: "textp3",
    bg: "bluebg1 ",
  },

  {
    className: "col-lg-6 col-md-9 col-sm-12",
    bgClass: "bluebg1 color-white",
    dataAos: "zoom-in-down",
    priceText: "$399",
    description: "Bumper Only ( Front or Rear)",
    discountText: "with 30% off",
    discountPrice: "$279.30",
    textClass: "text3",
    pClass: "p3",
    textP: "textp2",
    bg: "yellowbg",
  },

  {
    className: "col-lg-6 col-md-9 col-sm-12",
    bgClass: "yellowbg",
    dataAos: "zoom-in-down",
    priceText: "$149",
    description: "Door Slips  (2 door)",
    discountText: "with 30% off",
    discountPrice: "$104.30",
    textClass: "text2",
    pClass: "p2",
    bgtext: "bgtext",
    textP: "textp3",
    bg: "bluebg1 ",
  },

  {
    className: "col-lg-6 col-md-9 col-sm-12",
    bgClass: "bluebg1 color-white",
    dataAos: "zoom-in-down",
    priceText: "$199",
    description: "Rear  Cargo Lip",
    discountText: "with 30% off",
    discountPrice: "$139.30",
    textClass: "text3",
    pClass: "p3",
    textP: "textp2",
    bg: "yellowbg",
  },

  {
    className: "col-lg-6 col-md-9 col-sm-12",
    bgClass: "yellowbg",
    dataAos: "zoom-in-down",
    priceText: "$199",
    description: "Door Slips  (4 door)",
    discountText: "with 30% off",
    discountPrice: "$139.30",
    textClass: "text2",
    pClass: "p2",
    bgtext: "bgtext",
    textP: "textp3",
    bg: "bluebg1 ",
  },

  {
    className: "col-lg-6 col-md-9 col-sm-12",
    bgClass: "bluebg1 color-white",
    dataAos: "zoom-in-down",
    priceText: "$199",
    description: "Pillar and 6” Roof",
    discountText: "with 30% off",
    discountPrice: "$139.30",
    textClass: "text3",
    pClass: "p3",
    textP: "textp2",
    bg: "yellowbg",
  },

  {
    className: "col-lg-6 col-md-9 col-sm-12",
    bgClass: "yellowbg",
    dataAos: "zoom-in-down",
    priceText: "$149",
    description: "Door Edges  (2 door)",
    discountText: "with 30% off",
    discountPrice: "$104.30",
    textClass: "text2",
    pClass: "p2",
    bgtext: "bgtext",
    textP: "textp3",
    bg: "bluebg1 ",
  },

  {
    className: "col-lg-6 col-md-9 col-sm-12",
    bgClass: "bluebg1 color-white",
    dataAos: "zoom-in-down",
    priceText: "$399",
    description: "Tailgate",
    discountText: "with 30% off",
    discountPrice: "$279.30",
    textClass: "text3",
    pClass: "p3",
    textP: "textp2",
    bg: "yellowbg",
  },
  {
    className: "col-lg-6 col-md-9 col-sm-12",
    bgClass: "yellowbg",
    dataAos: "zoom-in-down",
    priceText: "$199",
    description: "Door Edges  (4 door)",
    discountText: "with 30% off",
    discountPrice: "$139.30",
    textClass: "text2",
    pClass: "p2",
    bgtext: "bgtext",
    textP: "textp3",
    bg: "bluebg1 ",
  },
];
