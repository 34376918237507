import * as Yup from "yup";

export const initialValues = {
  name: "",
  service: "",
  phone: "",
};

export const initialValues2 = {
  name: "",
  service: "Paint Protection Film",
  phone: "",
};

export const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  service: Yup.string().required("Choose a Service"),
  phone: Yup.string()
    .transform((value) => (value ? value.replace(/[^\d]/g, "") : value))
    .matches(/^[0-9]+$/, "Phone number must contain only digits")
    .min(10, "Phone number must be at least 10 digits")
    .max(14, "Phone number must be at most 14 digits")
    .required("Phone number is required"),
});
export const validationSchema2 = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  service: Yup.string().required("Paint Protection Film"),
  phone: Yup.string()
    .transform((value) => (value ? value.replace(/[^\d]/g, "") : value))
    .matches(/^[0-9]+$/, "Phone number must contain only digits")
    .min(10, "Phone number must be at least 10 digits")
    .max(14, "Phone number must be at most 14 digits")
    .required("Phone number is required"),
});
