import { Box, Typography, useTheme, Fab } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";

const CallSection = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme?.colors?.primary.main,
        display: "flex",
        textAlign: "center",
        flexWrap: "wrap",
        padding: "46px",
        flexDirection: "row",
        alignContent: "space-between",
        alignItems: "baseline",
        justifyContent: "center",
        gap: 5,
      }}
    >
      <Typography
        gutterBottom
        variant="subtitle1"
        component="div"
        sx={{
          fontSize: "33px",
          fontWeight: 900,
          color: theme?.colors?.alpha?.black[100],
        }}
      >
        Car Washing and Care Points
      </Typography>
      <Fab
        href="tel:+1 587-999-8722"
        variant="extended"
        sx={{
          backgroundColor: theme?.colors?.alpha.white[100],
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
          fontSize: 20,
        }}
      >
        <CallIcon sx={{ mr: 1 }} />
        +1 587-999-8722
      </Fab>
    </Box>
  );
};

export default CallSection;
