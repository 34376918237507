import React, { Component } from "react";
import { Link } from "react-router-dom";
import Subscribe from "src/components/Subscribe";
import GoogleReviews from "src/components/googleReviews";
import { Helmet } from "react-helmet";
export class index extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>About Us</title>
          <link
            id="canonical-tag-head"
            rel="canonical"
            href={window.location.href}
          />
        </Helmet>
        <section className="about-page about1">
          {/* Top banner section */}
          <div className="top-bg-inner">
            <div className="container">
              <div className="row">
                <div className="title-inner">
                  <h1 style={{ color: "var(--yellow)" }}>About us</h1>
                  <p>
                    Passionate auto detailing experts in Calgary, dedicated to
                    restoring and
                    <br />
                    enhancing your vehicle's aesthetic appeal. Experience
                    <br />
                    quality care and superior results with us.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-6 wash1">
            <div className="row justify-content-between align-items-center">
              <div className="col-lg-7 col-md-12 col-sm-12">
                <h4 className="bgh4">
                  Car wash &amp; deatiling service in Calgary
                </h4>
                <h2 style={{ fontWeight: 600 }}>
                  <span style={{ color: "var(--blue)" }}>T-BROS</span> CAR WASH
                </h2>
                <br />
                <p>
                  T-Bros Auto Detailing Services in Calgary is a business that
                  has been founded upon our combined ideas, expertise, and years
                  of experience. Our team of professionals is highly skilled and
                  passionate about providing the best auto detailing and
                  sanitizing services in Calgary to help our customers feel
                  proud and satisfied with their vehicles.
                </p>
                <div className="link-btn widhtbtn mt-5">
                  <a href="#" className="btnform">
                    Our Services
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12">
                <img
                  className="img-fluid"
                  src="assets/img/image12.webp"
                  data-aos="fade-right"
                />
              </div>
            </div>
          </div>
          <section className="whyself mt-6">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 p-0">
                  <img className="imgwidth" src="assets/img/image10.webp" />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 bg-yellow1">
                  <div className="row align-items-center">
                    <div className="left-space">
                      <div className="row larg-text">
                        <h2>
                          T-Bros Auto Detailing is a business that has been
                          founded upon our combined ideas, expertise, and years
                          of experience
                        </h2>
                        <p>
                          We strongly believe that keeping your car as clean as
                          possible and protecting it is the key to maintaining
                          its new-like appearance for a longer period. Our auto
                          detailing company in Calgary is committed to using
                          only the highest quality products and equipment,
                          combined with our expertise in detail, allows us to
                          deliver superior results that you can fully trust,
                          without any disappointments.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="whyself">
            <div className="container">
              <div className="row righttext">
                <div className="col-lg-6 col-md-12 col-sm-12 bg-blue1 p-0">
                  <div className="row align-items-center">
                    <div className="left-space">
                      <div className="row larg-text">
                        <h2>
                          Our auto detailing experts in Calgary understand that
                          your car is not just a means of transportation, but
                          also a reflection of your personality and style
                        </h2>
                        <p>
                          At T-Bros Auto Detailing, we understand that your car
                          is not just a means of transportation, but also a
                          reflection of your personality and style. That’s why
                          we take pride in our attention to detail and work hard
                          to ensure that every corner of your vehicle is
                          thoroughly cleaned and restored to its optimal
                          condition. With our affordable pricing and exceptional
                          service, we are the best auto detailing company in
                          Calgary for diverse solutions.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 p-0">
                  <img className="imgwidth" src="assets/img/dfdfw.webp" />
                </div>
              </div>
            </div>
          </section>
          <div className="image-with-text1 mt-6 ">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <img className="imgwidth" src="assets/img/team-image.webp" />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="row larg-text">
                    <h2>
                      {" "}
                      Meet the{" "}
                      <span style={{ color: "var(--blue)" }}>TEAM </span> of
                      highly
                      <br />{" "}
                      <span style={{ color: "var(--blue)" }}>
                        SKILLED PROFESSIONALS{" "}
                      </span>
                    </h2>
                    <p className="mt-3">
                      Our team of professionals is highly skilled and passionate
                      about providing the best auto detailing services in
                      Calgary to help our customers feel proud and satisfied
                      with their vehicles. We strongly believe that keeping your
                      car as clean as possible and protecting it is the key to
                      maintaining its new-like appearance for a longer period.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="call-to-action">
            <div className="container">
              <div className="row bg-yellow">
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <h2>Car Washing and Care Points</h2>
                </div>
                <div className="col-lg-4 col-md-12 cl-sm-12">
                  <p style={{ margin: 0 }}>
                    {" "}
                    <a href="tel:+1 587-999-8722;">
                      <i
                        className="bi bi-telephone-fill"
                        style={{ marginRight: 13 }}
                      />
                      +1 587-999-8722{" "}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </section>
          <div className="oursrevice1 mt-3">
            <div className="container">
              <div className="row larg-text">
                <h2>
                  Our
                  <span style={{ color: "var(--blue)" }}>
                    {" "}
                    PREMIUM AUTO DETAILING SERVICES in CALGARY{" "}
                  </span>{" "}
                </h2>
                <p className="mt-3">
                  Outstanding expertise, personalized service, and a commitment
                  to excellence – discover what sets us apart in delivering
                  exceptional results and experiences.
                </p>
              </div>
            </div>
          </div>
          <section className="gride-imge1 mt-5">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-12 col-sm-12 p-0">
                  <img className="imgwidth" src="assets/img/gide1.webp" />
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12 bg-yellow">
                  <div className="text">
                    <h3>
                      Showroom <br /> Precision
                    </h3>
                    <p>Precision craftsmanship for showroom-quality results.</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12 p-0">
                  <img className="imgwidth" src="assets/img/gide2.webp" />
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12 bg-yellow">
                  <div className="text">
                    <h3>
                      Tailored <br />
                      Excellence
                    </h3>
                    <p>
                      Personalized service crafted to your vehicle's unique
                      needs.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row righttext">
                <div className="col-lg-3 col-md-12 col-sm-12 bg-yellow">
                  <div className="text">
                    <h3>
                      Lasting <br /> Protection
                    </h3>
                    <p>Cutting-edge techniques for ever-lasting protection.</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12  p-0">
                  <img className="imgwidth" src="assets/img/gide3.webp" />
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12 bg-yellow">
                  <div className="text">
                    <h3>
                      Detailing <br />
                      Perfection
                    </h3>
                    <p>
                      Unparalleled attention to detail, ensuring a pristine
                      finish every time.
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12 p-0">
                  <img className="imgwidth" src="assets/img/gide4.webp" />
                </div>
              </div>
            </div>
          </section>
          <section className="whyself3 mt-6">
            <div className="container-fluid p-0">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="video-ply">
                    <div className="video-wrapper">
                      <video autoPlay muted loop playsInline preload="metadata">
                        <source
                          src="assets/img/t-brosvideo.mp4"
                          type="video/mp4"
                        />
                      </video>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="row align-items-center">
                    <div className="left-space">
                      <div className="row larg-text">
                        <h2 style={{ color: "var(--white)" }}>
                          The Power of
                          <span style={{ color: "var(--yellow)" }}>
                            {" "}
                            The Power of
                          </span>{" "}
                        </h2>
                        <p className="mt-3">
                          Experience the transformative power of detailing at T
                          Bros in Calgary. Our expert craftsmanship elevates
                          your vehicle, providing a showroom-quality finish.
                          With personalized service, we cater to the unique
                          needs of your car. Our innovative techniques provide
                          lasting protection, improving both aesthetics and
                          performance. At T Bros, we redefine auto detailing
                          services in Calgary, paying close attention to every
                          inch, and restoring your vehicle to its pristine
                          glory. Discover the difference our expertise makes in
                          bringing out the best in your ride.
                        </p>
                        <div className="link-btn widhtbtn mt-3">
                          <Link to="/booking">MAKE AN APPOINTMENT</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <GoogleReviews />
          <Subscribe />
        </section>
      </div>
    );
  }
}

export default index;
