import { reviewsRoot } from "src/interfaces";

export const reviews = {
  html_attributions: [],
  result: {
    address_components: [
      { long_name: "6120", short_name: "6120", types: ["subpremise"] },
      { long_name: "5850", short_name: "5850", types: ["street_number"] },
      {
        long_name: "88 Avenue Northeast",
        short_name: "88 Ave NE",
        types: ["route"],
      },
      {
        long_name: "Northeast Calgary",
        short_name: "Northeast Calgary",
        types: ["neighborhood", "political"],
      },
      {
        long_name: "Calgary",
        short_name: "Calgary",
        types: ["locality", "political"],
      },
      {
        long_name: "Alberta",
        short_name: "AB",
        types: ["administrative_area_level_1", "political"],
      },
      {
        long_name: "Canada",
        short_name: "CA",
        types: ["country", "political"],
      },
      { long_name: "T3J 0Z7", short_name: "T3J 0Z7", types: ["postal_code"] },
    ],
    adr_address:
      '\u003cspan class="street-address"\u003e5850 88 Ave NE #6120\u003c/span\u003e, \u003cspan class="locality"\u003eCalgary\u003c/span\u003e, \u003cspan class="region"\u003eAB\u003c/span\u003e \u003cspan class="postal-code"\u003eT3J 0Z7\u003c/span\u003e, \u003cspan class="country-name"\u003eCanada\u003c/span\u003e',
    business_status: "OPERATIONAL",
    current_opening_hours: {
      open_now: false,
      periods: [
        {
          close: { date: "2023-11-12", day: 0, time: "1630" },
          open: { date: "2023-11-12", day: 0, time: "1200" },
        },
        {
          close: { date: "2023-11-13", day: 1, time: "1800" },
          open: { date: "2023-11-13", day: 1, time: "0900" },
        },
        {
          close: { date: "2023-11-14", day: 2, time: "1800" },
          open: { date: "2023-11-14", day: 2, time: "0900" },
        },
        {
          close: { date: "2023-11-15", day: 3, time: "1800" },
          open: { date: "2023-11-15", day: 3, time: "0900" },
        },
        {
          close: { date: "2023-11-09", day: 4, time: "1800" },
          open: { date: "2023-11-09", day: 4, time: "0900" },
        },
        {
          close: { date: "2023-11-10", day: 5, time: "1800" },
          open: { date: "2023-11-10", day: 5, time: "0900" },
        },
        {
          close: { date: "2023-11-11", day: 6, time: "1700" },
          open: { date: "2023-11-11", day: 6, time: "1000" },
        },
      ],
      weekday_text: [
        "Monday: 9:00 AM – 6:00 PM",
        "Tuesday: 9:00 AM – 6:00 PM",
        "Wednesday: 9:00 AM – 6:00 PM",
        "Thursday: 9:00 AM – 6:00 PM",
        "Friday: 9:00 AM – 6:00 PM",
        "Saturday: 10:00 AM – 5:00 PM",
        "Sunday: 12:00 – 4:30 PM",
      ],
    },
    formatted_address: "5850 88 Ave NE #6120, Calgary, AB T3J 0Z7, Canada",
    formatted_phone_number: "(403) 200-0222",
    geometry: {
      location: { lat: 51.1328104, lng: -113.9492644 },
      viewport: {
        northeast: { lat: 51.1342673802915, lng: -113.9479146197085 },
        southwest: { lat: 51.1315694197085, lng: -113.9506125802915 },
      },
    },
    icon: "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/generic_business-71.webp",
    icon_background_color: "#7B9EB0",
    icon_mask_base_uri:
      "https://maps.gstatic.com/mapfiles/place_api/icons/v2/generic_pinlet",
    international_phone_number: "+1 403-200-0222",
    name: "T-Bros",
    opening_hours: {
      open_now: false,
      periods: [
        { close: { day: 0, time: "1630" }, open: { day: 0, time: "1200" } },
        { close: { day: 1, time: "1800" }, open: { day: 1, time: "0900" } },
        { close: { day: 2, time: "1800" }, open: { day: 2, time: "0900" } },
        { close: { day: 3, time: "1800" }, open: { day: 3, time: "0900" } },
        { close: { day: 4, time: "1800" }, open: { day: 4, time: "0900" } },
        { close: { day: 5, time: "1800" }, open: { day: 5, time: "0900" } },
        { close: { day: 6, time: "1700" }, open: { day: 6, time: "1000" } },
      ],
      weekday_text: [
        "Monday: 9:00 AM – 6:00 PM",
        "Tuesday: 9:00 AM – 6:00 PM",
        "Wednesday: 9:00 AM – 6:00 PM",
        "Thursday: 9:00 AM – 6:00 PM",
        "Friday: 9:00 AM – 6:00 PM",
        "Saturday: 10:00 AM – 5:00 PM",
        "Sunday: 12:00 – 4:30 PM",
      ],
    },
    photos: [
      {
        height: 1440,
        html_attributions: [
          '\u003ca href="https://maps.google.com/maps/contrib/106398004897890946716"\u003eT-Bros\u003c/a\u003e',
        ],
        photo_reference:
          "AcJnMuG9j4Pnm323nCjmfU6JKnDlr-xXHpw7gNIJ_lTTv4tTgWM27V522_7aXHxvKvhGPPw0X0qOFwjkRKA0KcBgHSzjLQm1bAg9lU5ltJDPOH7Cf0JeSiaTJWsj06KgJl0o4yd8R8ptJeCQV8Lld9e-NStX-WI_mCr3PjF7A6gzpoZS5JUn",
        width: 1440,
      },
    ],
    place_id: "ChIJ_-oH7vFjcVMRqdMEWuwZoDw",
    plus_code: {
      compound_code: "43M2+47 Calgary, AB, Canada",
      global_code: "953843M2+47",
    },
    rating: 5.0,
    reference: "ChIJ_-oH7vFjcVMRqdMEWuwZoDw",
    reviews: [
      {
        author_name: "Pratiksha Nepal",
        author_url:
          "https://www.google.com/maps/contrib/114584564315395397186/reviews/@51.1523402,-113.9808428,17z/data=!3m1!4b1!4m3!8m2!3m1!1e1?hl=en&entry=ttu",
        language: "en",
        original_language: "en",
        profile_photo_url:
          "https://lh3.googleusercontent.com/a-/ALV-UjWZ07VP7svLNrSz2ZEMZuY89GkFwbTdQLwxsCgblPdEbqw=w60-h60-p-rp-mo-br100",
        rating: 5,
        relative_time_description: "2 months ago",
        text: "Excellent service with best price in Calgary, I’m very much happy with my recent service of my Nissan Altima with T- Bros Auto Detailing. Very friendly staff and welcoming nature. I recommend everyone to try T-Bros Auto Detailing before anyone else for your Detailing, Headlight restoration, Ceramic coating, power head polishing, undercoating and rust protection work. Unbelievable price and quality work. Thumbs up guys 👍 thanks a lot",
        time: 1694374580,
        translated: false,
      },
      {
        author_name: "Vanessa Fahie",
        author_url:
          "https://www.google.com/maps/contrib/105250643573377160123/reviews?hl=en",
        language: "en",
        original_language: "en",
        profile_photo_url:
          "https://lh3.googleusercontent.com/a-/ALV-UjUCSQ-3FxSOKpEhrmVdbz0oXeHpj4ueqHipnEdFSrOcR7Om=w60-h60-p-rp-mo-ba3-br100",
        rating: 5,
        relative_time_description: "5 months ago",
        text: "I just had my car detailed at T-Bros Auto Detailing and it was an excellent experience. They did an amazing job on detailing the inside of my SUV but their customer service was also exceptional. I have a large long haired dog who travels everywhere with me do to the job she does and after having T-Bros detail the inside of my car you would not know that a dog had ever been in there. My SUV looks brand new. Their attention to detail, fair prices, quality service and exceptional costumer service will have me recommending them to all my friends and family.",
        time: 1691077852,
        translated: false,
      },
      {
        author_name: "manika gautam",
        author_url:
          "https://www.google.com/maps/contrib/111650065084400635117/reviews/@51.1431805,-113.9718877,15z/data=!3m1!4b1!4m3!8m2!3m1!1e1?hl=en&entry=ttu",
        language: "en",
        original_language: "en",
        profile_photo_url:
          "https://lh3.googleusercontent.com/a-/ALV-UjWlSJ1wbrekc-1k-aZSrHacR4cO5GjSBfe1prDno4vB25Y=s128-c0x00000000-cc-rp-mo",
        rating: 5,
        relative_time_description: "2 months ago",
        text: "I recently brought my first car with them and I must say, I am extremely satisfied with the service. The team did a fantastic job, they explained me each and every procedure and detail during my car buying process. They were thorough in their work, paying attention to every detail and ensuring that everything was in perfect working order. The exterior of the car looks brand new, with a flawless paint job and shiny finish. The interior was also meticulously cleaned and restored, making it feel like a new. Overall, I am impressed with the quality of work and the level of professionalism displayed by the team. I would highly recommend their services to anyone looking to get.",
        time: 1685950998,
        translated: false,
      },
      {
        author_name: "Calgary Guide",
        author_url:
          "https://www.google.com/maps/contrib/109571644009275858844/reviews/@51.191198,-114.0625431,11z/data=!3m1!4b1!4m3!8m2!3m1!1e1?hl=en&entry=ttu",
        language: "en",
        original_language: "en",
        profile_photo_url:
          "https://lh3.googleusercontent.com/a-/ALV-UjVZbWy_MvmJ7ektW0HSa1aURsJVXIOlQnwqDNGrVHnHlw=w60-h60-p-rp-mo-br100",
        rating: 5,
        relative_time_description: "5 months ago",
        text:"I can't say enough good things about T Bros Auto Detailing! Their dedication to making my car look and feel fantastic was truly impressive. The interior detailing was nothing short of remarkable – from the deep cleaning of the upholstery to the meticulous attention given to every nook and cranny, it felt like I was stepping into a showroom-quality vehicle. T Bros Auto Detailing's commitment to excellence is truly commendable, and I wholeheartedly recommend them for both interior and exterior detailing.",
        time: 1693528679,
        translated: false,
      },
      {
        author_name: "Ephraim TB",
        author_url:
          "https://www.google.com/maps/contrib/101956918206653830276/place/ChIJCWJNr7dhcVMRCJ0IWFT8cW0/@51.1523402,-113.9808428,17z/data=!3m1!4b1?entry=ttu",
        language: "en",
        original_language: "en",
        profile_photo_url:
          "https://lh3.googleusercontent.com/a/ACg8ocIJfQ2w0YmTQ83HT2H353zEX5ix6UPY3z-U-FmC4HY3x4M=w60-h60-p-rp-mo-ba2-br100",
        rating: 5,
        relative_time_description: "5 months ago",
        text: "It is a very amazing customer service and doing great job that they did . I was very impressed and absolutely recommend. Talk to Name called Bashedev Panta . Thank You .",
        time: 1685402338,
        translated: false,
      },
    ],
    types: ["travel_agency", "point_of_interest", "establishment"],
    url: "https://maps.app.goo.gl/Q6sv2u8ihHL8qsV7A",
    user_ratings_total: 150,
    utc_offset: -420,
    vicinity: "11124 36 St NE unit 3040,Calgary, AB T3N 1L3, Canada",
    website: "",
  },
  status: "OK",
} as reviewsRoot;
