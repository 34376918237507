import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Stack, Typography } from "@mui/material";
import Subscribe from "src/components/Subscribe";
import GoogleReviews from "src/components/googleReviews";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { headlightRestoration } from "src/components/FAQS/mockData";
import FAQItem from "src/components/FAQS";
class Index extends Component {
  state = {
    display: true,
    width: 300,
  };
  render() {
    const PrevArrow = (props) => {
      const { onClick } = props;
      return (
        <button
          className="carousel-control-prev"
          onClick={onClick}
          style={{
            left: "10px",
            zIndex: 1,
            backgroundColor: "#234DEF",
            width: "4%",
            borderRadius: "61%",
            height: "13%",
            margin: "auto",
          }}
        >
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="visually-hidden">Previous</span>
        </button>
      );
    };

    const NextArrow = (props) => {
      const { onClick } = props;
      return (
        <button
          className="carousel-control-next"
          onClick={onClick}
          style={{
            right: "10px",
            zIndex: 1,
            backgroundColor: "#234DEF",
            width: "4%",
            borderRadius: "61%",
            height: "13%",
            margin: "auto",
          }}
        >
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="visually-hidden">Next</span>
        </button>
      );
    };
    const slickSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
      responsive: [
        {
          breakpoint: 900, // adjust the breakpoint as needed
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: false,
            nextArrow: false,
          },
        },
      ],
    };

    return (
      <div>
        <Helmet>
          <title>
            Professional Car Headlight Restoration Services in Calgary AB
          </title>
          <meta
            name="description"
            content="Restore clarity to your headlights with T-Bros Auto Detailing's expert headlight restoration service in Calgary AB. Affordable and the best solutions available!"
          />
          <meta
            name="keywords"
            content="car headlight restoration services in calgary ab, auto headlight restoration service in calgary ab, vehicle headlight restoration service in calgary ab, affordable car headlight restoration services in calgary ab, Expert car headlight restoration service in calgary ab"
          />
          <link
            id="canonical-tag-head"
            rel="canonical"
            href={window.location.href}
          />
        </Helmet>
        <section className="about-page about1 whychhose">
          {/* Top banner section */}
          <div className="carpolish">
            <div className="container">
              <div className="row">
                <div className="title-inner">
                  <h1 style={{ color: "var(--yellow)" }}>
                    Headlight Restoration
                  </h1>
                  <p>
                    Revive clarity and brightness with our{" "}
                    <strong>
                      headlight <br />
                      restoration services in Calgary
                    </strong>{" "}
                    Enhance visibility and safety on the road <br />
                    road with renewed, crystal-clear headlights.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <section className="spce-contact1 mt-6">
            <div className="container">
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <h2 style={{ fontWeight: 600 }}>
                    When do
                    <span style={{ color: "#234DEF" }}>
                      {" "}
                      you need a headlight{" "}
                    </span>{" "}
                    <br />
                    restoration service in Calgary?{" "}
                  </h2>
                  <div className="che-box mt-4">
                    <div className="row">
                      <div className="col-1">
                        <img src="/assets/img/blue-cionss.svg" />
                      </div>
                      <div className="col-10">
                        <h4>Reduced visibility on the road</h4>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-1">
                        <img src="/assets/img/blue-cionss.svg" />
                      </div>
                      <div className="col-10">
                        <h4>Clouded and discolored headlights</h4>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-1">
                        <img src="/assets/img/blue-cionss.svg" />
                      </div>
                      <div className="col-10">
                        <h4>Yellowed lenses</h4>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-1">
                        <img src="/assets/img/blue-cionss.svg" />
                      </div>
                      <div className="col-10">
                        <h4>Scratches on the lenses</h4>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-1">
                        <img src="/assets/img/blue-cionss.svg" />
                      </div>
                      <div className="col-10">
                        <h4>Oxidized headlight glass</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 mb-3">
                  <img
                    className="imgwidth aos-init aos-animate"
                    src="/assets/img/iconheadimg3.webp"
                    data-aos="fade-right"
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="bg-blue pading89 mt-6">
            <div className="container">
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-6 col-md-12 col-sm-12 mb-3">
                  <img
                    className="imgwidth "
                    src="/assets/img/iconheadimg2.webp"
                    data-aos="fade-right"
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 color56">
                  <div className="che-box23 mt-4">
                    <div className="row">
                      <h3>
                        <span style={{ color: "var(--yellow)" }}>
                          Revitalize clarity{" "}
                        </span>
                      </h3>
                      <p>
                        Renew and enhance the brightness of your headlights for
                        improved visibility on the road with affordable car
                        headlight restoration services in Calgary.
                      </p>
                    </div>
                    <div className="row">
                      <h3>
                        <span style={{ color: "var(--yellow)" }}>
                          Safety upgrade
                        </span>{" "}
                      </h3>
                      <p>
                        Ensure a safer driving experience by restoring
                        headlights to their optimal condition.
                      </p>
                    </div>
                    <div className="row">
                      <h3>
                        <span style={{ color: "var(--yellow)" }}>
                          Cost-effective solution
                        </span>
                      </h3>
                      <p>
                        {" "}
                        Headlight restoration services in Calgary are a
                        budget-friendly alternative to replacement, extending
                        the life of your headlights.
                      </p>
                    </div>
                  </div>
                  <div className="link-btn widhtbtn mr-auto mt-5 mb-6">
                    <Link to="/booking">CHECK OUR PACKAGES</Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="spce-contact1 mt-6">
            <div className="container">
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <h2 style={{ fontWeight: 600 }}>
                    Benefits of
                    <span style={{ color: "#234DEF" }}>
                      {" "}
                      our headlight
                    </span>{" "}
                    <br />
                    restoration service in Calgary{" "}
                  </h2>
                  <div className="che-box mt-4">
                    <div className="row">
                      <div className="col-1">
                        <img src="/assets/img/blue-cionss.svg" />
                      </div>
                      <div className="col-10">
                        <h4>Scratch-free lenses</h4>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-1">
                        <img src="/assets/img/blue-cionss.svg" />
                      </div>
                      <div className="col-10">
                        <h4>Clean and bright headlamps</h4>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-1">
                        <img src="/assets/img/blue-cionss.svg" />
                      </div>
                      <div className="col-10">
                        <h4>Enhance performance and functionality</h4>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-1">
                        <img src="/assets/img/blue-cionss.svg" />
                      </div>
                      <div className="col-10">
                        <h4>Maximum visibility ensuring safety</h4>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-1">
                        <img src="/assets/img/blue-cionss.svg" />
                      </div>
                      <div className="col-10">
                        <h4>Affordable solution for replacement</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 mb-3">
                  <img
                    className="imgwidth"
                    src="/assets/img/iconheadimg.webp"
                    data-aos="fade-right"
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="three-icon45 bg-blue">
            <div className="container">
              <div className="row minus78">
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="ioc7">
                    <img src="/assets/img/iconheadlight3.webp" />
                    <h3>Expert Restoration</h3>
                    <p>
                      Expert headlight restoration in Calgary improves clarity,
                      enhances visibility, and ensures a safer drive, bringing
                      back the original brilliance of your headlights.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="ioc7">
                    <img src="/assets/img/iconheadlight2.webp" />
                    <h3>Competitively Priced</h3>
                    <p>
                      We offer affordable headlight restoration in Calgary to
                      ensure competitive value, our products/services meet high
                      standards without compromising your budget or
                      expectations.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="ioc7">
                    <img src="/assets/img/iconheadlight1.webp" />
                    <h3>Premium Quality Products</h3>
                    <p>
                      Elevate your experience with our premium-quality products,
                      ensuring excellence and satisfaction with every use.
                      Unmatched in performance and durability.
                    </p>
                  </div>
                </div>
              </div>
              <div className="link-btn widhtbtn m-auto mt-5 mb-6">
                <Link to="/booking">CHECK OUR PACKAGES</Link>
              </div>
            </div>
          </section>
          <div className="container mt-6 mb-2 p-0">
            <div className="row larg-text mb-2 mt-3 text-center">
              <h2>
                OUR <span style={{ color: "var(--blue)" }}> WORK</span>
              </h2>
            </div>
            <Stack>
              <div className="work-img mb-5">
                <Slider {...slickSettings} className="m-auto">
                  <div className="img-work1">
                    <img src="/assets/img/ourwork01.webp" alt="Work 1" />
                  </div>
                  <div className="img-work1">
                    <img src="/assets/img/ourwork02.webp" alt="Work 2" />
                  </div>
                  <div className="img-work1">
                    <img src="/assets/img/ourwork03.webp" alt="Work 3" />
                  </div>
                  <div className="img-work1">
                    <img src="/assets/img/ourwork04.webp" alt="Work 4" />
                  </div>
                  <div className="img-work1">
                    <img src="/assets/img/ourwork05.webp" alt="Work 5" />
                  </div>
                </Slider>
              </div>
            </Stack>
          </div>
          <div className="container">
            <Box sx={{ my: 10 }}>
              <Typography
                variant="h2"
                sx={{ display: "flex", justifyContent: "center", fontSize: 50 }}
                gutterBottom
              >
                FAQ’s
              </Typography>
              {headlightRestoration.map((headlightRestoration) => (
                <FAQItem
                  key={headlightRestoration.id}
                  question={headlightRestoration.question}
                  answer={headlightRestoration.answer}
                />
              ))}
            </Box>
          </div>
          <GoogleReviews />
          <Subscribe />
        </section>
      </div>
    );
  }
}

export default Index;
