import {
  bookingModelProps,
  contactUsProps,
  makeAnAppointmentProps,
} from "src/interfaces";
import { reviewsRoot } from "../../interfaces/reviews.interfaces";
import {
  FETCH_GOOGLE_REVIEWS,
  RESET_BOOKING_MODEL,
  SET_BOOKING_MODEL,
  SET_CLIENT_SECRET,
  SET_GOOGLE_REVIEWS,
  SUBMIT_APPOINTMENT_FORM,
  SUBMIT_CONTACT_FORM,
} from "./types";

export const fetchGoogleReview = () => {
  return {
    type: FETCH_GOOGLE_REVIEWS,
  };
};

export const setGoogleReview = (payload: reviewsRoot) => {
  return {
    type: SET_GOOGLE_REVIEWS,
    payload: payload,
  };
};

export const setBookingModel = (payload: bookingModelProps) => {
  return {
    type: SET_BOOKING_MODEL,
    payload: payload,
  };
};

export const resetBookingModel = () => {
  return {
    type: RESET_BOOKING_MODEL,
  };
};

export const setClientSecret = (payload: any) => {
  return {
    type: SET_CLIENT_SECRET,
    payload: payload,
  };
};

export const submitContactUs = (payload: contactUsProps) => {
  return {
    type: SUBMIT_CONTACT_FORM,
    payload: payload,
  };
};

export const submitAppointment = (payload: makeAnAppointmentProps) => {
  return {
    type: SUBMIT_APPOINTMENT_FORM,
    payload: payload,
  };
};
