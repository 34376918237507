import { FC, ReactNode } from "react";
import { Box, alpha, lighten, useTheme } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";

import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Footer from "../components/Footer";

interface SidebarLayoutProps {
  children?: ReactNode;
}

const BaseLayout: FC<SidebarLayoutProps> = () => {
  const theme = useTheme();
  const location = useLocation();
  const isHome = location?.pathname === "/";
  return (
    <>
      <Box
        sx={{
          flex: 1,
          height: "100%",
          ".MuiPageTitle-wrapper": {
            background: theme.colors.alpha.white[100],
            marginBottom: `${theme.spacing(4)}`,
            boxShadow:
              theme.palette.mode === "dark"
                ? `0 1px 0 ${alpha(
                    lighten(theme.colors.primary.main, 0.7),
                    0.15
                  )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
                : `0px 2px 4px -3px ${alpha(
                    theme.colors.alpha.secondary[100],
                    0.1
                  )}, 0px 5px 12px -4px ${alpha(
                    theme.colors.alpha.secondary[100],
                    0.05
                  )}`,
          },
        }}
      >
        <Header />
        <Sidebar />
        <Box
          sx={{
            minHeight: "89%",
            position: "relative",
            zIndex: 5,
            display: "block",
            flex: 1,
          }}
        >
          <Box
            sx={{
              height: "100%",
              minHeight: 200,
              backgroundColor: "white",
              pt: 10,
            }}
            display="block"
          >
            <Outlet />
          </Box>
          <Footer />
        </Box>
      </Box>
    </>
  );
};

export default BaseLayout;
