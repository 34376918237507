import moment from "moment";

export function isEmpty(str) {
  return !str || 0 === str.length;
}

export function isBlank(str) {
  return !str || /^\s*$/.test(str);
}
export const camalCaseToTitle = (text) => {
  const result = text?.replace(/([A-Z])/g, " $1");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

export function formatCardNumber(value) {
  var v = value?.replace(/\s+/g, "")?.replace(/[^0-9]/gi, "");
  var matches = v?.match(/\d{4,16}/g);
  var match = (matches && matches[0]) || "";
  var parts = [];
  for (let i = 0, len = match.length; i < len; i += 4) {
    parts.push(match.substring(i, i + 4));
  }
  if (parts.length) {
    return parts.join("-");
  } else {
    return value;
  }
}

export function formatExpiryDate(value) {
  var v = value?.replace(/\s+/g, "")?.replace(/[^0-9]/gi, "");
  var matches = v?.match(/\d{2,6}/g);
  var match = (matches && matches[0]) || "";
  var parts = [];
  if (match?.length > 2) {
    parts[0] = match.substring(0, 2);
    parts[1] = match.substring(2, match.length);
  }
  if (value?.length === 3 && value?.includes("/")) {
    return match?.substring(0, 2);
  }
  if (parts.length) {
    return parts.join("/");
  } else {
    return value;
  }
}

export const addTax = (priceData: any): any => {
  let tax = 5;
  return {};
};

export const convertToForm = (payload: any): FormData => {
  const keys = Object.keys(payload);
  let formData = new FormData();
  keys.map((key) => {
    formData.append(key, payload[key]);
  });
  return formData;
};

export const currencyFormat = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "INR",
});
export const dateParser = (date: Date | string) => {
  return moment.utc(date)
}