import { googleReviewsPlaceId } from "src/config";
import { fetch } from "./http";

const controller: string = "additional/";

export const googleReviewsService = async () => {
  try {
    const placeId = googleReviewsPlaceId;
    const response = await fetch.Get(
      `/api/google-reviews.php?placeId=${placeId}`,
      null,
    );
    if (response.status === 200) {
      const data = await response?.json();
      return data;
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error) { }
};

export const contactUsService = async (payload: any) => {
  try {
    const response = await fetch.Post(controller + 'contactus', payload);
    if (response.status === 200) {
      payload?.callback && payload?.callback()
      const data = await response?.json();
      return data;
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error) { }
};

export const makeAnAppointmentService = async (payload: any) => {
  try {
    const response = await fetch.Post(controller + 'get_call', payload);
    if (response.status === 200) {
      payload?.callback && payload?.callback()
      const data = await response?.json();
      return data;
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error) { }
};

