import React, { Component } from "react";
import { Link } from "react-router-dom";
import Subscribe from "src/components/Subscribe";
import GoogleReviews from "src/components/googleReviews";
import { Helmet } from "react-helmet";
import FAQItem from "src/components/FAQS";
import { exteriorDetailing } from "src/components/FAQS/mockData";
import { Box, Typography } from "@mui/material";
export class index extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>
            Affordable Car Exterior Detailing Services in Calgary AB
          </title>
          <meta
            name="description"
            content="Experience the best auto exterior detailing services in Calgary AB with T-Bros Auto Detailing. Our professionals ensure top-notch quality! Contact us today!"
          />
          <meta
            name="keywords"
            content="Car exterior detailing services in calgary ab, auto exterior detailing services in calgary ab, vehicle exterior detailing services in calgary ab, Best car exterior detailing services in calgary ab, affordable auto exterior detailing services in calgary ab, Professional auto exterior detailing services in calgary ab"
          />
          <link
            id="canonical-tag-head"
            rel="canonical"
            href={window.location.href}
          />
        </Helmet>
        <section className="about-page Interior12">
          {/* Top banner section */}
          <div className="top-bg-inner">
            <div className="container">
              <div className="row">
                <div className="title-inner">
                  <p>Our Service</p>
                  <h1 style={{ color: "var(--yellow)" }}>
                    Revamp Your Vehicle’s Exterior: <br /> Professional Exterior
                    <br />
                    Detailing Services in Calgary for a<br /> Showroom Finish
                  </h1>
                </div>
              </div>
            </div>
          </div>
          {/*End  Top banner section */}
          <section className="image-with-heading">
            <div className="container">
              <div className="row mt-space">
                <div className="col-lg-4 col-md-5 col-sm-12">
                  <img
                    className="img-fluid"
                    alt="waxing calgary"
                    src="/assets/img/exterior-img1.webp"
                  />
                </div>
                <div className="col-lg-8 col-md-7 col-sm-12 middle1">
                  <div className="text-with-img">
                    <h2 style={{ fontWeight: 600 }}>
                      Restore your RIDE! Our{" "}
                      <span
                        className="text-uppercase"
                        style={{ color: "#234DEF" }}
                      >
                        <strong>EXTERIOR DETAILING services in Calgary </strong>
                      </span>{" "}
                      bring back the shine, protect against the elements, and
                      ensure your car stands out with{" "}
                      <span
                        className="text-uppercase"
                        style={{ color: "#234DEF" }}
                      >
                        lasting brilliance.
                      </span>
                    </h2>
                    <div
                      className="link-btn widhtbtn"
                      style={{ marginTop: 30 }}
                    >
                      <Link to="/booking">CHECK OUR PACKAGES</Link>
                    </div>
                  </div>
                </div>
                <div className="bgimage1"></div>
              </div>
            </div>
          </section>
          <div className="detailing-bg mt-space">
            <div className="container">
              <div className="row mt-space">
                <div className="col-lg-6 col-md-12 col-sm-12 p-0">
                  <div className="text-with-img">
                    <h2 style={{ color: "var(--white)", fontWeight: 500 }}>
                      Revitalize and Shine The Ultimate{" "}
                      <span style={{ color: "#FCF003" }}>
                        <br /> Exterior Detailing Services{" "}
                      </span>
                      <span style={{ color: "var(--white)" }}>
                        Transformation Package
                      </span>
                    </h2>
                    <p className="p-white">
                      Elevate your vehicle's exterior aesthetics with our
                      professional auto exterior detailing services. Our skilled
                      team ensures a meticulous hand wash and drying process.
                      Preserve your car's exterior brilliance with our premium
                      wax application, delivering a glossy, smooth finish while
                      safeguarding the paint from environmental wear. Witness
                      the complete transformation as we clean and rejuvenate
                      your wheels, tires, and wells, ensuring your car boasts a
                      polished appearance from every angle. Trust us for a
                      detailed service that enhances the beauty of your beloved
                      vehicle.
                    </p>
                    <div className="link-btn widhtbtn mt-5">
                      <Link to="/booking">MAKE AN APPOINTMENT</Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 p-0">
                  <img
                    className="img-fluid"
                    alt="vinyl for cars exterior"
                    src="/assets/img/exterior-img2.webp"
                    data-aos="zoom-out-up"
                  />
                </div>
              </div>
            </div>
          </div>
          <section className="about11 mt-6 p-text-fonts">
            <div className="container">
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-7 col-md-12 col-sm-12 mb-3">
                  <img
                    className="imgwidth"
                    src="/assets/img/exterior-img3.webp"
                    alt="vinyl car paint exterior"
                    data-aos="fade-right"
                  />
                </div>
                <div className="col-lg-5 col-md-12 col-sm-12">
                  <h2 style={{ fontWeight: 600 }}>
                    Calgary’s{" "}
                    <span style={{ color: "#234DEF" }}>Exterior Detailing</span>{" "}
                    Best Exterior Detailing Service Provider
                  </h2>
                  <br />
                  <p>
                    Unleash the ultimate exterior glow for your vehicle with our
                    professional car exterior detailing services in Calgary. Our
                    skilled professionals bring expertise and care to every
                    inch, ensuring a flawless finish. Trust us for the best
                    deal, providing unmatched quality and lasting brilliance to
                    your car's exterior.
                  </p>
                  <div className="link-btn widhtbtn mt-5">
                    <Link to="/booking">MAKE AN APPOINTMENT</Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="gride-video12">
            {" "}
            <div className="video-ply mt-6" data-aos="fade-up">
              <div className="video-wrapper">
                <video autoPlay muted playsInline preload="auto">
                  <source src="/assets/img/t-brosvideo.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="container">
              <div className="row w-100">
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="card-box yellowbg" data-aos="flip-right">
                    <div className="svg-img">
                      <img
                        src="/assets/img/exterior1.webp"
                        data-aos="fade-right"
                      />
                    </div>
                    <h4>
                      Restore the lustre, eliminating imperfections and
                      enhancing your vehicle's overall appearance.
                    </h4>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="card-box bluebg1" data-aos="flip-right">
                    <div className="svg-img">
                      <img
                        src="/assets/img/exterior2.webp"
                        data-aos="fade-right"
                      />
                    </div>
                    <h4>
                      Deep cleansing removes contaminants, ensuring a pristine
                      surface and preserving your vehicle's paint.
                    </h4>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="card-box yellowbg" data-aos="flip-right">
                    <div className="svg-img">
                      <img
                        src="/assets/img/exterior3.webp"
                        data-aos="fade-right"
                      />
                    </div>
                    <h4>
                      Eliminate particles, and prepare the surface for polishing
                      and protection.
                    </h4>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="card-box bluebg1" data-aos="flip-right">
                    <div className="svg-img">
                      <img
                        src="/assets/img/exterior4.webp"
                        data-aos="fade-right"
                      />
                    </div>
                    <h4>
                      Enhance shine with polishing, smoothing out imperfections,
                      and bringing out the brilliance.
                    </h4>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="card-box yellowbg" data-aos="flip-right">
                    <div className="svg-img">
                      <img
                        src="/assets/img/exterior5.webp"
                        data-aos="fade-right"
                      />
                    </div>
                    <h4>
                      Protect with ceramic coatings, providing lasting defence
                      against UV rays, oxidation, and pollutants.
                    </h4>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="card-box bluebg1" data-aos="flip-right">
                    <div className="svg-img">
                      <img
                        src="/assets/img/exterior6.webp"
                        data-aos="fade-right"
                      />
                    </div>
                    <h4>
                      Renew faded trims, bringing back richness to enhance the
                      overall aesthetic appeal.
                    </h4>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="card-box yellowbg" data-aos="flip-right">
                    <div className="svg-img">
                      <img
                        src="/assets/img/exterior7.webp"
                        data-aos="fade-right"
                      />
                    </div>
                    <h4>
                      Improve visibility and aesthetics by restoring clarity to
                      headlights, ensuring safer driving.
                    </h4>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="card-box bluebg1" data-aos="flip-right">
                    <div className="svg-img">
                      <img
                        src="/assets/img/exterior8.webp"
                        data-aos="fade-right"
                      />
                    </div>
                    <h4>
                      Elevating overall appearance with specialized care for
                      wheels and tires, adding sophistication.
                    </h4>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="card-box yellowbg" data-aos="flip-right">
                    <div className="svg-img">
                      <img
                        src="/assets/img/exterior9.webp"
                        data-aos="fade-right"
                      />
                    </div>
                    <h4>
                      Conclude with attention to detail, ensuring every inch of
                      your vehicle reflects a flawless, showroom-quality finish.
                    </h4>
                  </div>
                </div>
              </div>
              <div className="link-btn widhtbtn m-auto mb-10">
                <Link to="/booking">MAKE AN APPOINTMENT</Link>
              </div>
            </div>
          </section>
          <div className="container">
            <Box sx={{ my: 10 }}>
              <Typography
                variant="h2"
                sx={{ display: "flex", justifyContent: "center", fontSize: 50 }}
                gutterBottom
              >
                FAQ’s
              </Typography>
              {exteriorDetailing.map((exteriorDetailing) => (
                <FAQItem
                  key={exteriorDetailing.id}
                  question={exteriorDetailing.question}
                  answer={exteriorDetailing.answer}
                />
              ))}
            </Box>
          </div>
          <GoogleReviews />
          <Subscribe />
        </section>
      </div>
    );
  }
}

export default index;
